import { useCallback } from 'react'
import axios from 'axios'
import { useCurrentNetwork } from './useNetwork'

export const useMarketplace = (isActive = true, isGen0: boolean, isNewGen1?): (() => Promise<any>) => {
  const currentNetwork = useCurrentNetwork()
  return useCallback(async (): Promise<any> => {
    try {
      let url
      if (isActive) {
        url = `${isGen0 ? currentNetwork?.urlApiGen0 : currentNetwork?.urlApi}/getsells/?isActive=${isActive}`
      } else {
        url = `${isGen0 ? currentNetwork?.urlApiGen0 : currentNetwork?.urlApi}/getsells/`
      }

      const response = await axios.get(url)

      if (response.status === 200 && response.data) {
        if (isNewGen1 == true) {
          return response.data.sells.filter(sell => sell.nftContract == currentNetwork?.newGen1Contract.NFT)
        } else if (isNewGen1 == false)
          return response.data.sells.filter(sell => sell.nftContract == currentNetwork?.contract.Box)
        else {
          return response.data.sells
        }
      }
    } catch (error: any) {
      console.error(error)
      return []
    }
  }, [])
}
