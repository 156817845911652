import styled from 'styled-components/macro'

const PriceWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const PriceText = styled.span`
  font-size: 14px;
  font-weight: 500;
`

interface IPriceProps {
  price: string | number | undefined
  symbol?: string
}

function PriceTag(props: IPriceProps): JSX.Element {
  const { price, symbol } = props

  return (
    <PriceWrapper>
      <PriceText>
        {price ? price : '--'} {symbol}
      </PriceText>
    </PriceWrapper>
  )
}

export default PriceTag
