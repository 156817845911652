import { useEffect, useState } from 'react'
import axios from 'axios'
import {
  StatusText,
  SectionWrapper,
  WrapText,
  ButtonWrap,
  StyledLink,
  ConnectButton,
  StyledContainer,
  CancelButton,
  WrapContentCongratulations,
  TextCongratulations,
  WrapTextCongratulations,
  WrapCongratulations,
  WrapImage,
  WrapPortfolio,
  TextNft,
  ContainerContentNft,
  WrapImagePortfolio,
  TextTokenId,
  TextAttributes,
} from './Styled'
import { useAccount, useConnectorId, useWalletProvider } from 'state/wallet/hooks'
import { ThinSectionTitle } from 'components/SectionTitle'
import ConnectModal from 'components/ConnectButton/ConnectModal'
import HourGlassLogo from 'assets/images/hourglass-logo.svg'
import RedeemBackground from 'assets/images/redeem-background.png'
import PunksMacroBackground from 'assets/images/punks-macro-background.png'
import Button from 'components/Button'
import ListAttribute from 'components/ListAttribute'
import { useCurrentNetwork } from 'hooks/useNetwork'
import { useParams, useHistory } from 'react-router-dom'
import {
  CasperClient,
  CLPublicKey,
  CLValueBuilder,
  DeployUtil,
  RuntimeArgs,
  CLByteArray,
  CasperServiceByJsonRPC,
} from 'casper-js-sdk'
import { getDeployFunction } from 'utils'
import { useActiveWeb3React, useTimestamp } from 'hooks'
import { useTransactionAdder } from 'state/transactions/hooks'
import { toast } from 'react-toastify'
import { useRedeemNFTs } from 'hooks/useNFTs'
import { CEP78Client } from 'casper-cep78-js-client'
import Loader from 'components/Loader'

const dummyData = {
  edition: 1,
  attributes: [{ trait_type: 'body', value: 'Grey' }],
  image: '',
}

function RedeemPage(): JSX.Element {
  const currentNetwork = useCurrentNetwork()
  const account = useAccount()

  const { tokenId } = useParams<{ tokenId: string }>()
  const provider = useWalletProvider()
  const connectorId = useConnectorId()
  const { connector } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()
  const timestampCallback = useTimestamp()

  const [redeemed, setRedeemed] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [showConnectModal, setShowConnectModal] = useState(false)
  const [metadata, setMetadata] = useState(dummyData)
  const [txHash, setTxHash] = useState('')
  const [isOwner, setIsOwner] = useState(false)
  const history = useHistory()
  const redeemNFTsCb = useRedeemNFTs(account)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    async function getOwner() {
      try {
        if (tokenId && account) {
          const response = await axios.get(
            `${currentNetwork?.apiMake}/contract-packages/${currentNetwork?.contract.BoxPackageHash}/nft-tokens/${tokenId}?fields=contract_package`,
          )

          if (response.status === 200 && response.data) {
            if (response.data.owner_public_key !== null) {
              const isOwner_ = response.data.owner_public_key == account
              setIsOwner(isOwner_)
            } else {
              const _account = CLPublicKey.fromHex(account).toAccountHashStr()
              const _splitAccountHex = _account.split('-')
              console.log('_splitAccountHex[2]', _splitAccountHex[2])
              const isOwner_ = response.data.owner_account_hash == _splitAccountHex[2]
              setIsOwner(isOwner_)
            }
          }
        }
      } catch (error: any) {
        console.error(error)
        // get owner onchain
        try {
          if (account && currentNetwork) {
            setLoading(true)
            const _cep78Client = await CEP78Client.createInstance(
              currentNetwork.contract.Box,
              currentNetwork.rpcURL,
              currentNetwork.key ?? 'casper-test',
            )
            const arrayTokenId = await _cep78Client.getOwnedTokenIds(CLPublicKey.fromHex(account))
            const isOwner_ = arrayTokenId.find(e => e == tokenId)
            setIsOwner(isOwner_ ? true : false)
          }
        } catch (e) {
          console.error('get onchain failed', e)
        }
        setLoading(false)
      }
    }

    getOwner()
  }, [account])

  useEffect(() => {
    async function checkTransactionConfirm() {
      if (redeemed && !confirm) {
        try {
          const rpc = currentNetwork ? currentNetwork.rpcURL : ''
          const client = new CasperServiceByJsonRPC(rpc)
          client
            .getDeployInfo(txHash)
            .then(async receipt => {
              if (receipt.execution_results.length > 0) {
                if (receipt.execution_results[0].result.Success != undefined) {
                  //delay 3s get redeem nfts
                  await new Promise(resolve => setTimeout(resolve, 3000)) // 3 sec
                  const redeemNFTs = await redeemNFTsCb()
                  if (redeemNFTs.length > 0) {
                    await getMetadata(redeemNFTs[0].token_id)
                    setConfirm(true)
                  } else {
                    toast.error('Something went wrong.')
                  }
                } else {
                  setRedeemed(false)
                  toast.error('Failed!.')
                }
              }
            })
            .catch(e => console.log('e', e))
        } catch (e) {
          //@ts-ignore
          toast.error(e.message)
        }
      }
    }

    const timerId = setInterval(() => {
      checkTransactionConfirm()
    }, 5000)
    return () => {
      clearInterval(timerId)
    }
  }, [redeemed, confirm, txHash])

  const getMetadata = async tokenId_ => {
    try {
      const api = `${currentNetwork?.urlApi}/metadata/${tokenId_}`
      const response = await axios.get(api)
      if (response.status === 200 && response.data) {
        setMetadata(response.data)
      }
    } catch (e) {
      setMetadata(dummyData)
      console.log('fail get metadata', e)
      toast.error('Fail to get metadata')
    }
  }
  const handleRedeem = async () => {
    try {
      if (account && currentNetwork) {
        const senderKey = CLPublicKey.fromHex(account)
        const gasFee = '50000000000'
        let boxPackageHash = currentNetwork.contract.BoxPackageHash
        boxPackageHash = CLValueBuilder.key(new CLByteArray(Uint8Array.from(Buffer.from(boxPackageHash, 'hex'))))
        let redeemPackageHash = currentNetwork.redeemContract.redeemPackageHash
        redeemPackageHash = CLValueBuilder.key(new CLByteArray(Uint8Array.from(Buffer.from(redeemPackageHash, 'hex'))))
        let redeemContractHash = currentNetwork.redeemContract.redeemContractHash
        redeemContractHash = CLValueBuilder.key(
          new CLByteArray(Uint8Array.from(Buffer.from(redeemContractHash, 'hex'))),
        )
        const timestamp = await timestampCallback()
        const deployParams = new DeployUtil.DeployParams(
          senderKey,
          currentNetwork?.key ?? 'casper-test',
          1,
          1800000,
          [],
          timestamp,
        )

        const runtimeArgs = RuntimeArgs.fromMap({
          token_ids: CLValueBuilder.list([CLValueBuilder.u64(Number(tokenId))]),
          redeem_package_hash: redeemPackageHash,
          redeem_contract_hash: redeemContractHash,
          box_package_hash: boxPackageHash,
        })
        const response = await axios.get('/redeem_session.wasm', {
          responseType: 'arraybuffer',
        })
        const instance = new Uint8Array(Buffer.from(response.data, 'binary'))

        const deploy = DeployUtil.makeDeploy(
          deployParams,
          DeployUtil.ExecutableDeployItem.newModuleBytes(instance, runtimeArgs),
          DeployUtil.standardPayment(gasFee),
        )
        if (deploy && provider) {
          const json = DeployUtil.deployToJson(deploy)
          const casperClient = new CasperClient(currentNetwork.rpcURL)
          const hash = await getDeployFunction(account, casperClient, connectorId, deploy, provider, json, connector)
          if (hash) {
            setRedeemed(true)
            setTxHash(hash)
            await addTransaction(hash, {
              summary: `Redeem.`,
            })
          }
        }
      }
    } catch (e: any) {
      //@ts-ignore
      console.log('redeem failed', e.message)
      setRedeemed(false)
      //@ts-ignore
      toast.error(e.message)
    }
  }
  return (
    <>
      {!confirm ? (
        <SectionWrapper bgImage={RedeemBackground}>
          <StyledContainer>
            {!redeemed ? (
              <div className="col col-md-9 col-lg-8 col-xl-7">
                <ThinSectionTitle data-aos="fade-right" data-aos-delay="400">
                  <span>
                    Redeem
                    <br />
                    Mystery Box?
                  </span>
                </ThinSectionTitle>
                <WrapText>
                  <StatusText color="success">
                    You will receive one CasperPunk digital collectible in exchange for redeeming your Mystery Box.
                    Redeeming a Mystery Box for a Gen 1 CasperPunk can not be undone, are you sure?
                  </StatusText>
                </WrapText>
                {!account && (
                  <ConnectButton
                    type="primary"
                    data-aos="fade-up"
                    data-aos-delay="600"
                    handleClick={() => setShowConnectModal(true)}
                  >
                    Connect Wallet
                  </ConnectButton>
                )}
                {account &&
                  (isOwner ? (
                    <ButtonWrap>
                      <ConnectButton
                        type="primary"
                        data-aos="fade-up"
                        data-aos-delay="600"
                        handleClick={async () => {
                          await handleRedeem()
                          // setConfirm(true)
                          // setMetadata({
                          //   edition: 1,
                          //   attributes: [{ trait_type: 'body', value: 'Grey' }],
                          //   image: '',
                          // })
                        }}
                      >
                        Redeem
                      </ConnectButton>
                      <CancelButton
                        data-aos="fade-up"
                        data-aos-delay="700"
                        type="outline"
                        handleClick={() => {
                          history.goBack()
                        }}
                      >
                        Cancel
                      </CancelButton>
                    </ButtonWrap>
                  ) : (
                    <>
                      {loading ? (
                        <Loader size="30px" stroke="white" />
                      ) : (
                        <WrapText>
                          <StatusText color="fail">You are not owner.</StatusText>
                        </WrapText>
                      )}
                    </>
                  ))}
              </div>
            ) : (
              <WrapCongratulations>
                {/* <ThinSectionTitleCongratulations data-aos="fade-right" data-aos-delay="400">
                  <span>Congratulations!</span>
                </ThinSectionTitleCongratulations> */}
                <WrapContentCongratulations>
                  <WrapImage>
                    <img src={HourGlassLogo}></img>
                  </WrapImage>
                  <WrapTextCongratulations>
                    <TextCongratulations>
                      Please wait while we generate your CasperPunk digital collectible.
                    </TextCongratulations>
                    <TextCongratulations>
                      Do not refresh the page, this could take several minutes.{' '}
                    </TextCongratulations>
                  </WrapTextCongratulations>
                </WrapContentCongratulations>
              </WrapCongratulations>
            )}

            <ConnectModal show={showConnectModal} onHide={() => setShowConnectModal(false)} />
          </StyledContainer>
        </SectionWrapper>
      ) : (
        <SectionWrapper bgImage={PunksMacroBackground}>
          <ContainerContentNft>
            <WrapImagePortfolio>
              <img src={metadata?.image}></img>
            </WrapImagePortfolio>
            <WrapPortfolio>
              <TextNft>Meet your new CasperPunk!</TextNft>
              <TextTokenId>#{metadata?.edition}</TextTokenId>
              <TextAttributes>Attributes</TextAttributes>
              <ListAttribute metadata={metadata}></ListAttribute>
              <Button
                type="primary"
                data-aos="fade-up"
                data-aos-delay="600"
                handleClick={async () => {}}
                padding="5px 5px"
              >
                <StyledLink href="/portfolio">View In Portfolio</StyledLink>
              </Button>
            </WrapPortfolio>
          </ContainerContentNft>
        </SectionWrapper>
      )}
    </>
  )
}

export default RedeemPage
