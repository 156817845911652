import { ReactElement, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styled from 'styled-components/macro'
import { useCurrentNetwork, useMarketInfo, useMarketplace, useMetadata } from 'hooks'
import { useAccount, useChainId } from 'state/wallet/hooks'
import AuctionBox from 'components/AuctionBox'
import Loader from 'components/Loader'

import ConnectModal from 'components/ConnectButton/ConnectModal'
import Layout from 'components/Layout'
import Select from 'react-select'
import MarketInfo, { Info } from 'components/MarketInfo'

const SectionWrapper = styled.div`
  padding: 5rem 0;

  @media (min-width: 992px) {
    padding: 2.5rem 0;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  margin-top: 5rem;
`

const BoxWrap = styled.div`
  margin-bottom: 0.5rem;
  min-height: 475px;
`

const PageTitle = styled.h1`
  color: #000000;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 0;
`

const PageSubTitle = styled.p`
  color: #000000;
  font-size: 30px;
  line-height: 1;
  font-weight: 400;
  margin-bottom: 0;
`
const SoftWrap = styled.div`
  display: flex;
  min-height: 44px;
  padding: 0 30px;
  background: #f5f5f5;
  justify-content: start;
  align-items: center;
  gap: 30px;
  margin-top: 40px;
`

const SortTitle = styled.h4`
  color: #000000;
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 500;
  line-height: 40px;
`

// fn compare
function compareTokens(a, b) {
  const arrA = a.tokenId.split('-')
  const arrB = b.tokenId.split('-')

  for (let i = 0; i < arrA.length; i++) {
    if (!isNaN(arrA[i])) {
      arrA[i] = parseInt(arrA[i])
      arrB[i] = parseInt(arrB[i])
    }
  }

  for (let i = 0; i < arrA.length; i++) {
    if (arrA[i] < arrB[i]) {
      return -1
    } else if (arrA[i] > arrB[i]) {
      return 1
    }
  }

  return a.tokenId.localeCompare(b.tokenId)
}

const options = [
  { value: 'tokenId-low-to-high', label: 'Lowest ID' },
  { value: 'tokenId-high-to-low', label: 'Highest ID' },
  { value: 'minimumOffer-low-to-high', label: 'Lowest Price' },
  { value: 'minimumOffer-high-to-low', label: 'Highest Price' },
  { value: 'lastPrice-low-to-high', label: 'Lowest Last Price' },
  { value: 'lastPrice-high-to-low', label: 'Highest Last Price' },
]

function Gen0Boxes(): ReactElement {
  const account = useAccount()
  const chainId = useChainId()
  const currentNetwork = useCurrentNetwork()
  const metadataCallback = useMetadata(true)
  const sellsCallback = useMarketplace(false, true)
  const marketInfoCallback = useMarketInfo(true)

  const [showConnectModal, setShowConnectModal] = useState(false)
  const [tokenMarket, setTokenMarket] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [marketInfo, setMarketInfo] = useState<Info>({
    totalVolume: '0',
    floorPrice: '0',
    bestOffer: '0',
    listedPercentage: '0',
    bestBidTokenId: '',
    floorBidTokenId: '',
  })
  const [tokenMeta, setTokenMeta] = useState<any>()
  const [tokenArr, setTokenArr] = useState<any>()
  const [filterOption, setFilterOption] = useState(options.find(option => option.value === 'tokenId-low-to-high'))

  //
  const customStyles = {
    container: provided => ({
      ...provided,
      borderRadius: '4px',
      padding: '8px',
      background: 'transparent',
    }),
    control: (control, state) => ({
      ...control,
      background: state.isFocused ? '#fff' : 'transparent',
      borderColor: state.hover ? 'red' : 'none',
      borderWidth: '0',
      minWidth: '185px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
  }

  const sortItems = (option, items) => {
    const itemsCopy = [...items]

    switch (option.value) {
      case 'tokenId-low-to-high':
        itemsCopy.sort(compareTokens)
        break
      case 'tokenId-high-to-low':
        itemsCopy.sort(compareTokens).reverse()
        break
      case 'minimumOffer-low-to-high':
        itemsCopy.sort((a, b) => {
          const offerA = a.minimumOffer && a.isActive ? parseInt(a.minimumOffer) : Infinity
          const offerB = b.minimumOffer && b.isActive ? parseInt(b.minimumOffer) : Infinity
          return offerA - offerB
        })
        break
      case 'minimumOffer-high-to-low':
        itemsCopy.sort((a, b) => {
          const offerA = a.minimumOffer && a.isActive ? parseInt(a.minimumOffer) : -Infinity
          const offerB = b.minimumOffer && b.isActive ? parseInt(b.minimumOffer) : -Infinity
          return offerB - offerA
        })
        break
      case 'lastPrice-low-to-high':
        itemsCopy.sort((a, b) => {
          const priceA = a.lastPrice ? parseInt(a.lastPrice) : Infinity
          const priceB = b.lastPrice ? parseInt(b.lastPrice) : Infinity
          return priceA - priceB
        })
        break
      case 'lastPrice-high-to-low':
        itemsCopy.sort((a, b) => {
          const priceA = a.lastPrice ? parseInt(a.lastPrice) : -Infinity
          const priceB = b.lastPrice ? parseInt(b.lastPrice) : -Infinity
          return priceB - priceA
        })
        break
      default:
        itemsCopy.sort((a, b) => a.tokenId - b.tokenId)
        break
    }
    return itemsCopy
  }

  const handleSelectChange = selected => {
    setFilterOption(selected)
  }

  const sortedItems = async () => {
    try {
      setLoading(true)

      const _sortItems = sortItems(filterOption, tokenArr)
      setTokenArr(_sortItems)
    } catch (error) {
      console.error(error)
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  const fetchNFTs = async () => {
    try {
      setLoading(true)

      if (currentNetwork) {
        // @ts-ignore
        const _nfts = await sellsCallback()
        const _meta = await metadataCallback()
        setTokenMarket(_nfts)
        setTokenMeta(_meta)
      }
    } catch (error) {
      console.error(error)
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  const mergeMeta = () => {
    if (tokenMeta?.length > 0) {
      let result = [
        ...[...tokenMarket, ...tokenMeta]
          .reduce((acc, curr) => acc.set(curr.tokenId, { ...acc.get(curr.tokenId), ...curr }), new Map())
          .values(),
      ]
      result = sortItems(filterOption, result)
      setTokenArr(result)
    }
  }

  useEffect(() => {
    sortedItems()
  }, [tokenMarket, filterOption])

  const fetMarketInfo = async () => {
    const _marketInfo = await marketInfoCallback()
    setMarketInfo(_marketInfo)
  }

  useEffect(() => {
    fetMarketInfo()
    const interval = setInterval(() => {
      fetMarketInfo()
    }, 1000 * 60)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    mergeMeta()
    const interval = setInterval(() => {
      mergeMeta()
    }, 1000 * 60)

    return () => clearInterval(interval)
  }, [tokenMarket, tokenMeta, filterOption])

  useEffect(() => {
    fetchNFTs()
  }, [account, chainId, currentNetwork])

  return (
    <Layout>
      <SectionWrapper>
        <Container>
          <Row>
            <Col style={{ marginBottom: '15px' }} xl={4}>
              <PageTitle>Gen 0</PageTitle>
              <PageSubTitle>CasperPunks</PageSubTitle>
            </Col>
            <Col xl={8}>
              <MarketInfo marketInfo={marketInfo} contractHash={currentNetwork?.contractGen0?.NFT} />
            </Col>
          </Row>
          <SoftWrap>
            <SortTitle>Sort By</SortTitle>
            {/*
              // @ts-ignore */}
            <Select options={options} value={filterOption} onChange={handleSelectChange} styles={customStyles} />
          </SoftWrap>
          {loading ? (
            <ContentWrapper>
              <Loader size="40px" />
            </ContentWrapper>
          ) : (
            <>
              {tokenArr?.length > 0 ? (
                <>
                  <Row style={{ marginTop: 30 }}>
                    {tokenArr.map((nft, index) => (
                      <Col xs={12} md={6} lg={4} xl={3} key={index}>
                        <BoxWrap>
                          <AuctionBox
                            nft={nft}
                            showBtn={true}
                            isGen0={true}
                            connectWalletCallback={() => {
                              setShowConnectModal(true)
                            }}
                          />
                        </BoxWrap>
                      </Col>
                    ))}
                  </Row>
                </>
              ) : (
                <ContentWrapper>No Digital Collectibles found.</ContentWrapper>
              )}
            </>
          )}
        </Container>
        <ConnectModal show={showConnectModal} onHide={() => setShowConnectModal(false)} />
      </SectionWrapper>
    </Layout>
  )
}

export default Gen0Boxes
