import Modal from 'components/Modal'
import ConfirmationPendingContent from './ConfirmationPendingContent'
import TransactionSubmittedContent from './TransactionSubmittedContent'

interface TransactionConfirmationModalProps {
  isOpen: boolean
  title: string
  attemptingTxn: boolean
  hash: string | undefined
  pendingText: string
  content: () => React.ReactNode
  onDismiss: () => void
}

function TransactionConfirmationModal(props: TransactionConfirmationModalProps): JSX.Element {
  const { isOpen, title, attemptingTxn, hash, pendingText, content, onDismiss } = props

  return (
    <>
      <Modal size="lg" show={isOpen} onHide={onDismiss} title={attemptingTxn ? '' : title}>
        <div>
          {attemptingTxn ? (
            <ConfirmationPendingContent pendingText={pendingText} />
          ) : hash ? (
            <TransactionSubmittedContent hash={hash} />
          ) : (
            content()
          )}
        </div>
      </Modal>
    </>
  )
}

export default TransactionConfirmationModal
