/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import networks from 'config/networks.json'
import Network from 'type/Network'
import { useChainId } from 'state/wallet/hooks'
import { AppState } from 'state'

export const useAllNetworks = (isTestnet?: boolean): Network[] => {
  return useMemo(() => {
    return (isTestnet ? networks.filter(n => n.isTestnet) : networks.filter(n => !n.isTestnet)) as Network[]
  }, [isTestnet, networks])
}

export const useNetworkInfo = (chainId?: number): Network | undefined => {
  return useMemo(() => {
    if (chainId) {
      return networks.find(n => n.chainId === chainId) as Network | undefined
    }
    return networks.find(n => n.chainId === Number(process.env.REACT_APP_CHAIN_ID)) as Network | undefined
  }, [networks, chainId])
}

export const useCurrentNetwork = (): Network | undefined => {
  const chainId = useChainId()
  const currentNetwork = useNetworkInfo(chainId)

  return useMemo(() => currentNetwork, [chainId])
}

export const useTargetNetwork = (): Network | undefined => {
  const targetNetwork = useSelector((state: AppState) => state.application.targetNetwork)
  return useMemo(() => targetNetwork, [targetNetwork])
}
