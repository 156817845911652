import styled from 'styled-components/macro'

export const SectionWrapper = styled.div`
  padding: 5rem 0;

  @media (min-width: 992px) {
    padding: 2.5rem 0;
  }
`

export const WalletWrapper = styled.div`
  margin-top: 5rem;

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  @media (min-width: 1200px) {
    justify-content: center;
    align-items: flex-start;
    gap: 4rem;
  }
`

export const WalletBox = styled.div`
  background: #fff;
  border: 1px solid #cacaca;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
  border-radius: 13px;
  padding: 2rem 1.5rem;
  text-align: center;
  margin-bottom: 2rem;
  height: 500px;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-0.5rem);
  }
`

export const WalletImage = styled.img`
  margin-bottom: 1.5rem;
`

export const WalletName = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
`

export const WalletDescription = styled.p<{ mb?: string }>`
  font-weight: 400;
  font-size: 15px;
  line-height: 27px;
  margin-bottom: ${({ mb }) => (mb ? mb : '1rem')};
`

export const ButtonWrapper = styled.div`
  margin-top: 2rem;

  button {
    margin: auto;
  }

  button:first-child {
    margin-bottom: 1rem;
  }
`
