import { useEffect, useState } from 'react'
import { useAccount } from 'state/wallet/hooks'
import ConnectModal from './ConnectModal'
import AccountModal from 'components/AccountModal'
import Button from 'components/Button'
import { CiWallet } from 'react-icons/ci'

function ConnectButton(): JSX.Element {
  const account = useAccount()

  const [walletAccount, setWalletAccount] = useState<any>()
  const [showConnectModal, setShowConnectModal] = useState(false)
  const [showAccountModal, setShowAccountModal] = useState(false)

  useEffect(() => {
    if (account) {
      setWalletAccount(account)
    }
  }, [account])

  const accountEllipsis = walletAccount
    ? `${walletAccount.substring(0, 6)}...${walletAccount.substring(walletAccount.length - 4)}`
    : ''

  return (
    <>
      {account ? (
        <>
          <Button handleClick={() => setShowAccountModal(true)}>{accountEllipsis}</Button>
          <AccountModal account={account} show={showAccountModal} onHide={() => setShowAccountModal(false)} />
        </>
      ) : (
        <>
          <Button handleClick={() => setShowConnectModal(true)}>
            <CiWallet size={20} />
            Connect Wallet
          </Button>
          <ConnectModal show={showConnectModal} onHide={() => setShowConnectModal(false)} />
        </>
      )}
    </>
  )
}

export default ConnectButton
