import styled, { keyframes } from 'styled-components/macro'
import { CgSpinner } from 'react-icons/cg'

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`
const Spinner = styled(CgSpinner)`
  animation: ${spin} 0.75s linear infinite;
  margin-right: 0.5rem;
`

interface ConfirmationPendingContentProps {
  pendingText: string
}

function ConfirmationPendingContent(props: ConfirmationPendingContentProps): JSX.Element {
  const { pendingText } = props

  return (
    <div className="text-center">
      <Spinner size={32} className="mb-2" />
      <h3>Waiting For Confirmation</h3>
      <p>{pendingText}</p>
      <p>
        <small>Confirm this transaction in your wallet</small>
      </p>
    </div>
  )
}

export default ConfirmationPendingContent
