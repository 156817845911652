import styled from 'styled-components/macro'
import DefaultNFTImage from 'assets/images/nft.png'
import { useEffect, useState } from 'react'

const WrapTitle = styled.p`
  color: #565660;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 12px;
`

const OwnerWapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 3rem;
    margin-right: 12px;
    border-radius: 50%;
  }

  h6 {
    font-size: 14px;
    line-height: 18px;
    color: #191820;
    margin: 0;
  }
`

interface IDetails {
  owner: any
  // metaData: any
  symbol: any
}

function DetailsTab(props: IDetails): JSX.Element {
  const { owner, symbol } = props

  const [ownerEllipsis, setOwnerEllipsis] = useState()

  useEffect(() => {
    if (owner) {
      const _ownerEllipsis = owner ? `${owner.substring(0, 6)}...${owner.substring(owner.length - 4)}` : owner

      setOwnerEllipsis(_ownerEllipsis)
    }
  }, [owner])

  return (
    <>
      {owner && (
        <>
          <div className="d-flex justify-content-start align-items-center gap-5">
            <div>
              <WrapTitle>Current Owner</WrapTitle>
              <OwnerWapper>
                <img src={DefaultNFTImage} alt="" />
                <h6>{ownerEllipsis}</h6>
              </OwnerWapper>
            </div>
            <div>
              <WrapTitle>Creator</WrapTitle>
              <OwnerWapper>
                <img src={DefaultNFTImage} alt="" />
                <h6>{symbol}</h6>
              </OwnerWapper>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default DetailsTab
