import styled from 'styled-components/macro'
import Author from 'assets/images/creator3.jpg'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { formatDistanceToNow, parseISO } from 'date-fns'
import { getExplorerLink, priceBid, toRoundedReadableNumber, trimName } from 'utils'
import PriceTag from 'components/PriceTag'
import { useCurrentNetwork } from 'hooks'

const ContentWrapper = styled.div`
  position: relative;
`

const AuthorAvt = styled.img`
  border-radius: 4px;
  margin-right: 12px;
`

const HistoryWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 1rem;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #565660;
  margin-bottom: 1.5rem;

  span {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #191820;
  }
`

const Text = styled.p`
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
`
const StyledSpan = styled.span`
  font-weight: 400 !important;
  font-size: 12px !important;
`

interface IHistory {
  isGen0: boolean
  nftContract: string
  tokenId: string
}

function HistoryTab(props: IHistory): JSX.Element {
  const { nftContract, tokenId, isGen0 } = props
  const [history, setHistory] = useState<any>([])
  const currentNetwork = useCurrentNetwork()

  const getHistory = async () => {
    if (nftContract && tokenId) {
      try {
        const response = await axios.get(
          `${isGen0 ? currentNetwork?.urlApiGen0 : currentNetwork?.urlApi}/gethistories/${nftContract}/${tokenId}`,
        )

        setHistory(response.data.actions)
      } catch (e) {
        console.error(e)
      }
    }
  }

  useEffect(() => {
    getHistory()

    const interval = setInterval(() => {
      getHistory()
    }, 1000 * 60)
    return () => clearInterval(interval)
  }, [nftContract, tokenId])

  return (
    <ContentWrapper>
      {history.length > 0 ? (
        <>
          {history.map((e, index) => (
            <HistoryWrapper key={index}>
              <div className="d-flex justify-content-start align-items-center">
                <AuthorAvt src={Author} />
                <div>
                  {e?.event_type === 'sell' && (
                    <>
                      <a href={getExplorerLink(currentNetwork, e?.offeror, 'address')} target="_blank">
                        <span>{trimName(e?.offeror, 5, 5, 20)}</span>
                      </a>
                      <StyledSpan> sell</StyledSpan>
                    </>
                  )}
                  {e?.event_type === 'donesell' && (
                    <>
                      <a href={getExplorerLink(currentNetwork, e?.buyer, 'address')} target="_blank">
                        <span>{trimName(e?.buyer, 5, 5, 20)}</span>
                      </a>
                      <StyledSpan> buy</StyledSpan>
                    </>
                  )}
                  {e?.event_type === 'bid' && (
                    <>
                      <a href={getExplorerLink(currentNetwork, e?.bidder, 'address')} target="_blank">
                        <span>{trimName(e?.bidder, 5, 5, 20)}</span>
                      </a>
                      <StyledSpan> bid</StyledSpan>
                    </>
                  )}
                  {e?.event_type === 'revokebid' && (
                    <>
                      <a href={getExplorerLink(currentNetwork, e?.bidder, 'address')} target="_blank">
                        <span>{trimName(e?.bidder, 5, 5, 20)}</span>
                      </a>
                      <StyledSpan> revoke bid</StyledSpan>
                    </>
                  )}
                  {e?.event_type === 'revoke' && (
                    <>
                      <a href={getExplorerLink(currentNetwork, e?.offeror, 'address')} target="_blank">
                        <span>{trimName(e?.offeror, 5, 5, 20)}</span>
                      </a>
                      <StyledSpan> revoke sell</StyledSpan>
                    </>
                  )}
                  <br />
                  {formatDistanceToNow(parseISO(e?.timestamps))}
                </div>
              </div>
              <div className="text-right">
                {e?.event_type === 'bid' ? (
                  <PriceTag price={priceBid(e?.minimumOffer)} symbol="CSPR" />
                ) : (
                  <PriceTag
                    price={toRoundedReadableNumber({
                      decimals: 9,
                      number: e?.minimumOffer,
                      precision: 3,
                    })}
                    symbol="CSPR"
                  />
                )}
              </div>
            </HistoryWrapper>
          ))}
        </>
      ) : (
        <Text>No history transaction.</Text>
      )}
    </ContentWrapper>
  )
}

export default HistoryTab
