import { ConnectorNames } from 'connectors'
import Modal from 'components/Modal'
import CasperSignerLogo from 'assets/images/WalletLogos/casper-logo.png'
import CasperDashLogo from 'assets/images/WalletLogos/casper-dash.png'
import CasperWalletLogo from 'assets/images/WalletLogos/casper-wallet.png'
import TorusWalletLogo from 'assets/images/WalletLogos/torus-logo.svg'
import WalletButton from './WalletButton'

export type Wallet = {
  name: string
  connector: ConnectorNames
  image: string
  disable?: boolean
}

const wallets: Wallet[] = [
  {
    name: 'Casper Signer',
    connector: ConnectorNames.CasperSigner,
    image: CasperSignerLogo,
  },
  {
    name: 'Casper Dash',
    connector: ConnectorNames.CasperDash,
    image: CasperDashLogo,
  },
  {
    name: 'Casper Wallet',
    connector: ConnectorNames.CasperWallet,
    image: CasperWalletLogo,
    disable: false,
  },
  {
    name: 'Torus',
    connector: ConnectorNames.TorusWallet,
    image: TorusWalletLogo,
  },
]

interface IConnectModalProps {
  show: boolean
  onHide: () => void
}

function ConnectModal(props: IConnectModalProps): JSX.Element {
  const { show, onHide } = props

  return (
    <Modal show={show} title="Connect Wallet" onHide={onHide}>
      {wallets.map(w => (
        <WalletButton key={w.connector} wallet={w} onDismiss={onHide} />
      ))}
    </Modal>
  )
}

export default ConnectModal
