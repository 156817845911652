import { Container } from 'react-bootstrap'
import { ContentList, ContentText, ContentTitle, SectionWrapper, TitleWrapper } from './Styled'
import { SectionTitle } from '../../components/Section2'

function CookiePolicy(): JSX.Element {
  return (
    <SectionWrapper>
      <Container>
        <TitleWrapper>
          <SectionTitle>
            Website cookie
            <br />
            policy
          </SectionTitle>
        </TitleWrapper>
        <ContentTitle>What are cookies and Why do We Use them?</ContentTitle>
        <ContentText>
          When you use our Services (as defined in our Terms of Services), including our website Casperpunks.io (the
          “Site”), we use cookies, pixels, and similar technologies to gather data about your use of our Site.
          <br />
          <br />
          Cookies are small files that we may send to your browser when you first use the Site, which are then stored on
          your device in order to remember information about you (such as login details). Those cookies are set by us
          and called first-party cookies. We also use third-party cookies – which are cookies from a domain different
          than the domain of the Site you are visiting – for our advertising and marketing efforts.
          <br />
          <br />
          We use cookies in a few different ways under the following categories:
          <br />
          <br />
        </ContentText>

        <ContentText>
          <span>‍Necessary Cookies</span> - These are cookies that are required for the operation of our Site or to
          comply with legal requirements. These cannot be switched off in our systems. They are usually only set in
          response to actions made by you which amount to a request for services.
          <br />
          <br />
          They include, for example, cookies that enable you to log into secure areas of our Site or help to ensure the
          content of pages loads quickly. You can set your browser to block or alert you about these cookies, but some
          parts of our Site will not then work.
          <br />
          <br />
        </ContentText>

        <ContentText>
          <span>Analytical or Performance Cookies</span> - These allow us to recognise and count the number of visitors
          and to see how visitors move around our Site when they are using them. This helps us to improve the way our
          Site work, for example, by ensuring that users are finding what they are looking for easily. If you do not
          allow these cookies, we will not know when you have visited our Site.
          <br />
          <br />
          We also use pixels (also referred to as web beacons, clear gifs, pixel tags, and single-pixel gifs). A pixel
          contains a tiny image that may be embedded within web pages and emails, requiring a call (which provides
          device and visit information) to our servers in order for the pixel to be rendered in those web pages and
          emails. We use pixels to learn more about your interactions with email content or web content, such as whether
          you interacted with ads or posts.
          <br />
          <br />
          Cookies and pixels collect data about you but are typically constructed with privacy by design. Only the
          server that sets the cookie can retrieve the data it collects, and the data is often collected on an
          anonymized basis.
          <br />
          <br />
          Some content or applications on the Site are served by third-parties. These third parties may use cookies
          alone or in conjunction with web beacons or other tracking technologies to collect information about you when
          you use our Site. The information collected by these cookies is directly obtained by such third parties, in
          some cases are not disclosed to us, are used and managed in accordance with the privacy and cookies policies
          of those third parties and are not under our control. We do not control these third parties’ tracking
          technologies or how they may be used. For more information about such cookies, you should contact the
          responsible provider directly.
          <br />
          <br />
        </ContentText>
        <ContentTitle>What are cookies and Why do We Use them?</ContentTitle>
        <ContentText>
          You decide if you want to consent to our use of cookies or not. When you first visit our Site, you are
          presented with a cookie banner that allows you to either opt in or opt out from our use of cookies. If you
          consent to our use of cookies, such cookies will be sent to and stored on your device.
          <br />
          <br />
          If you opt out and do not consent, we have a special script in place which ensures that no cookies that
          collect your data are being used. You can also choose not to allow some types of cookies. However, blocking
          some types of cookies may impact your experience of the Site and the Services we are able to offer.
          <br />
          <br />
          All browser technologies enable you to manage the cookies in the cookie folder of your web browser. This means
          that you can either delete cookies from your cookie folder once you have finished your visit to our Site or
          you can set your preferences with regard to the use of cookies before you begin browsing our Site. Please
          note, as stated above, that deleting or rejecting cookies may adversely affect your user experience of our
          Site.
          <br />
          <br />
          You can find out further information at:
          <br />
          <ContentList>
            <li>Google</li>
            <li>Firefox</li>
            <li>Windows</li>
            <li>Safari</li>
          </ContentList>
        </ContentText>
        <ContentTitle>Changes To This Cookies Policy</ContentTitle>
        <ContentText>
          ‍From time to time, we may make changes to this Cookies Policy. If we decide to do so, we will post the
          changes on our Site, so that you are always aware of what cookies we use and how we use them.
          <br />
          <br />
        </ContentText>
        <ContentTitle>Contact Details</ContentTitle>
        <ContentText>
          ‍If you have any questions, comments or requests relating to our use of cookies, or if you would like to
          exercise any of your rights under this policy, then please contact us at punk@casperpunks.io.
        </ContentText>
      </Container>
    </SectionWrapper>
  )
}

export default CookiePolicy
