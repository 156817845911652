import React from 'react'
import styled from 'styled-components/macro'

const StyledLayout = styled.div`
  min-height: 50vh;
  @media (min-width: 992px) {
    min-height: calc(100vh - 500px);
  }
`

interface ILayoutProps {
  children: React.ReactNode
}

const Layout = (props: ILayoutProps): JSX.Element => {
  const { children } = props
  return <StyledLayout>{children}</StyledLayout>
}

export default Layout
