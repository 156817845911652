import { Container } from 'react-bootstrap'
import SectionTitle from 'components/SectionTitle'
import {
  ButtonWrapper,
  SectionWrapper,
  WalletBox,
  WalletDescription,
  WalletImage,
  WalletName,
  WalletWrapper,
} from './Styled'
import { ConnectorNames } from 'connectors'
import Button from 'components/Button'
import Layout from 'components/Layout'
import { useConnectWalletCallback } from 'state/wallet/hooks'
import CasperDash from 'assets/images/ConnectWallet/casperdash.png'
import CasperSigner from 'assets/images/ConnectWallet/caspersigner.png'
import TorusWallet from 'assets/images/ConnectWallet/toruswallet.png'

type Wallet = {
  name: string
  connector: ConnectorNames
  image: string
  description: string
  extensionUrl?: string
  mb?: string
}

const wallets: Wallet[] = [
  {
    name: 'Casper Dash',
    connector: ConnectorNames.CasperDash,
    image: CasperDash,
    description:
      'Store, send and receive tokens on Casper ecosystem, store digital assets and tokens via Google Chrome extension & mobile app',
    extensionUrl: 'https://chrome.google.com/webstore/detail/casperdash/hmfpdofehnmfnoaneplbcpejindkoafd',
  },
  {
    name: 'Torus Wallet',
    connector: ConnectorNames.TorusWallet,
    image: TorusWallet,
    description:
      'Torus Wallet Sign in or setup a new account via Torus Wallet (using OAuth with google, facebook, twitch, reddit, discord and more)',
  },
  {
    name: 'Casper Signer',
    connector: ConnectorNames.CasperSigner,
    image: CasperSigner,
    description: 'Casper Signer Connect and sign in via Google Chrome extension',
    extensionUrl: 'https://chrome.google.com/webstore/detail/casper-signer/djhndpllfiibmcdbnmaaahkhchcoijce',
    mb: '5.25rem',
  },
]

function ConnectWallet(): JSX.Element {
  const connectWalletCallback = useConnectWalletCallback()

  const onConnect = async connector => {
    await connectWalletCallback(connector)
  }

  return (
    <Layout>
      <SectionWrapper>
        <Container>
          <SectionTitle color="dark">Connect Wallet</SectionTitle>
          <WalletWrapper>
            {wallets.map(wallet => (
              <WalletBox className="col-md-5 col-xl-3">
                <WalletImage src={wallet.image} />
                <WalletName>{wallet.name}</WalletName>
                {wallet.mb ? (
                  <WalletDescription mb={wallet.mb}>{wallet.description}</WalletDescription>
                ) : (
                  <WalletDescription>{wallet.description}</WalletDescription>
                )}
                <ButtonWrapper>
                  <Button type="primary" padding="5px 3.5rem" handleClick={() => onConnect(wallet.connector)}>
                    Sign In
                  </Button>
                  {wallet.extensionUrl && (
                    <a href={wallet.extensionUrl} target="_blank">
                      <Button type="secondary" padding="5px 3rem">
                        Download
                      </Button>
                    </a>
                  )}
                </ButtonWrapper>
              </WalletBox>
            ))}
          </WalletWrapper>
        </Container>
      </SectionWrapper>
    </Layout>
  )
}

export default ConnectWallet
