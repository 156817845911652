import { useCallback } from 'react'
import axios from 'axios'
import { useCurrentNetwork } from './useNetwork'

export const useTimestamp = (): (() => Promise<any>) => {
  const currentNetwork = useCurrentNetwork()
  return useCallback(async (): Promise<any> => {
    try {
      const response = await axios.get(`${currentNetwork?.urlApi}/timestamp`)
      if (response.status === 200 && response.data) {
        return response.data.timestamp
      }
    } catch (error: any) {
      console.error(error)
      return Date.now()
    }
  }, [])
}
