import Auctions from './Auctions'
// import SectionHero from './SectionHero'
// import Collection from './Collection'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import HeroBanner from './HeroBanner'
import Section2 from '../../components/Section2'
import Section4 from '../../components/Section4'

function HomePage(): JSX.Element {
  return (
    <>
      <HeroBanner />
      {/*<SectionHero />*/}
      <Auctions />
      <Section2 />
      <Section4 />
    </>
  )
}

export default HomePage
