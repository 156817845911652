import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { createWeb3ReactRoot, Web3ReactProvider } from '@dotoracle/web3-react-core'
import { Web3Provider } from '@ethersproject/providers'
import { NetworkContextName } from './constants'
import store from 'state'
import App from './App'
import TransactionUpdater from './state/transactions/updater'
import ApplicationUpdater from './state/application/updater'

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getLibrary = (provider: any): Web3Provider => {
  const library = new Web3Provider(provider, 'any')
  library.pollingInterval = 15000
  return library
}

function Updaters() {
  return (
    <>
      <ApplicationUpdater />
      <TransactionUpdater />
    </>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3ProviderNetwork getLibrary={getLibrary}>
        <Provider store={store}>
          <Updaters />
          <App />
        </Provider>
      </Web3ProviderNetwork>
    </Web3ReactProvider>
    <ToastContainer position="top-right" autoClose={3000} />
  </React.StrictMode>,
  document.getElementById('root'),
)
