import { useCallback } from 'react'
import axios from 'axios'

import { CLPublicKey } from 'casper-js-sdk'
import { useCurrentNetwork } from './useNetwork'

export const useSells = (
  account: string | undefined | null,
  isGen0: boolean,
  nftContractHash,
): (() => Promise<any>) => {
  const currentNetwork = useCurrentNetwork()
  return useCallback(async (): Promise<any> => {
    try {
      if (account) {
        const _accountHash = CLPublicKey.fromHex(account).toAccountHashStr()
        const accountHash = _accountHash.split('-')[2]
        const response = await axios.get(
          `${
            isGen0 ? currentNetwork?.urlApiGen0 : currentNetwork?.urlApi
          }/getsells?offeror=${accountHash}&isActive=true`,
        )
        if (response.status === 200 && response.data.sells) {
          if (!isGen0) {
            return response.data.sells.filter(sell => sell.nftContract == nftContractHash)
          }
          return response.data.sells
        }
      }
    } catch (error: any) {
      console.error(error)
      return []
    }
  }, [account, isGen0, nftContractHash])
}

export const useSell = (
  tokenId: string | undefined | null,
  isGen0: boolean,
  contractHash?: string | undefined | null,
): (() => Promise<any>) => {
  const currentNetwork = useCurrentNetwork()
  return useCallback(async (): Promise<any> => {
    try {
      if (tokenId) {
        let url
        if (contractHash) {
          url = `${
            isGen0 ? currentNetwork?.urlApiGen0 : currentNetwork?.urlApi
          }/getsells?tokenId=${tokenId}&nftContract=${contractHash}`
        } else {
          url = `${isGen0 ? currentNetwork?.urlApiGen0 : currentNetwork?.urlApi}/getsells?tokenId=${tokenId}`
        }
        const response = await axios.get(url)
        if (response.status === 200 && response.data.sells) {
          return response.data.sells
        }
      }
    } catch (error: any) {
      console.error(error)
      return []
    }
  }, [tokenId, isGen0])
}
