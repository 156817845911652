import { useHistory } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'
import styled from 'styled-components/macro'
import HeroBg from 'assets/images/SectionHero/hero-banner.jpg'
import Logo from 'assets/images/SectionHero/logo.png'
import Button from '../../components/Button'

const HeroBannerWrap = styled.div`
  position: relative;
  background-image: url(${HeroBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100vw;
  min-height: 600px;
`

const HeroBannerContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 600px;
  background: rgba(22, 31, 47, 0.8);
  backdrop-filter: blur(4px);
  @media (min-width: 768px) {
    width: 50%;
    min-height: 650px;
  }
  img {
    margin-bottom: 30px;
  }
  h2,
  p {
    color: #ffffff;
  }

  h2 {
    font-size: 35px;
    font-weight: 800;
  }

  p {
    max-width: 340px;
    margin-bottom: 40px;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    @media (min-width: 1024px) {
      max-width: 420px;
    }
  }
`

function HeroBanner(): JSX.Element {
  const history = useHistory()
  return (
    <HeroBannerWrap>
      <Row>
        <Col>
          <HeroBannerContent>
            <img src={Logo} alt="" />
            <h2>Punk Up Your Life</h2>
            <p>Rare, Unique, Digital Collectibles with Expanding Utility</p>
            <Button type="primary" padding="17px 30px" handleClick={() => history.push({ pathname: `/gen1boxes` })}>
              Explore now
            </Button>
          </HeroBannerContent>
        </Col>
      </Row>
    </HeroBannerWrap>
  )
}

export default HeroBanner
