import { ReactElement, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styled from 'styled-components/macro'
import { useCurrentNetwork, useMarketInfo, useMarketplace } from 'hooks'
import { useAccount, useChainId } from 'state/wallet/hooks'
import AuctionBox from 'components/AuctionBox'
import Loader from 'components/Loader'
import ReactPaginate from 'react-paginate'
import Select from 'react-select'

import ConnectModal from 'components/ConnectButton/ConnectModal'
import Layout from 'components/Layout'
import MarketInfo, { Info } from 'components/MarketInfo'

const SectionWrapper = styled.div`
  padding: 5rem 0;

  @media (min-width: 992px) {
    padding: 2.5rem 0;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  margin-top: 5rem;
`

const BoxWrap = styled.div`
  margin-bottom: 0.5rem;
  min-height: 475px;
`

const SoftWrap = styled.div`
  display: flex;
  min-height: 44px;
  padding: 0 30px;
  background: #f5f5f5;
  justify-content: start;
  align-items: center;
  gap: 30px;
  margin-top: 40px;
`

const SortTitle = styled.h4`
  color: #000000;
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 500;
  line-height: 40px;
`

const PageTitle = styled.h1`
  color: #000000;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 0;
`

const PageSubTitle = styled.p`
  color: #000000;
  font-size: 30px;
  line-height: 1;
  font-weight: 400;
  margin-bottom: 0;
`

const itemsPerPage = 12

const options = [
  { value: 'tokenId-low-to-high', label: 'Lowest ID' },
  { value: 'tokenId-high-to-low', label: 'Highest ID' },
  { value: 'minimumOffer-low-to-high', label: 'Lowest Price' },
  { value: 'minimumOffer-high-to-low', label: 'Highest Price' },
  { value: 'lastPrice-low-to-high', label: 'Lowest Last Price' },
  { value: 'lastPrice-high-to-low', label: 'Highest Last Price' },
]

function MyteryBox(): ReactElement {
  const account = useAccount()
  const chainId = useChainId()
  const currentNetwork = useCurrentNetwork()
  const sellsCallback = useMarketplace(false, false, false)
  const marketInfoCallback = useMarketInfo(false, currentNetwork?.contract?.Box)

  const [showConnectModal, setShowConnectModal] = useState(false)
  const [tokenMarket, setTokenMarket] = useState<any>([])
  const [tokenMarketFilter, setTokenMarketFilter] = useState<any>([])
  // @ts-ignore
  const [filterOption, setFilterOption] = useState(options.find(option => option.value === 'tokenId-low-to-high'))
  const [itemOffset, setItemOffset] = useState(0)
  const endOffset = itemOffset + itemsPerPage

  const currentItems = tokenMarketFilter?.slice(itemOffset, endOffset)
  const pageCount = Math.ceil(tokenMarketFilter?.length / itemsPerPage)
  const [loading, setLoading] = useState(false)
  const [marketInfo, setMarketInfo] = useState<Info>({
    totalVolume: '0',
    floorPrice: '0',
    bestOffer: '0',
    listedPercentage: '0',
    bestBidTokenId: '',
    floorBidTokenId: '',
  })

  const customStyles = {
    container: provided => ({
      ...provided,
      borderRadius: '4px',
      padding: '8px',
      background: 'transparent',
    }),
    control: (control, state) => ({
      ...control,
      background: state.isFocused ? '#fff' : 'transparent',
      borderColor: state.hover ? 'red' : 'none',
      borderWidth: '0',
      minWidth: '185px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
  }

  const sortItems = (option, items) => {
    const itemsCopy = [...items]
    switch (option.value) {
      case 'tokenId-low-to-high':
        itemsCopy.sort((a, b) => a.tokenId - b.tokenId)
        break
      case 'tokenId-high-to-low':
        itemsCopy.sort((a, b) => b.tokenId - a.tokenId)
        break
      case 'minimumOffer-low-to-high':
        itemsCopy.sort((a, b) => {
          const offerA = a.minimumOffer && a.isActive ? parseInt(a.minimumOffer) : Infinity
          const offerB = b.minimumOffer && b.isActive ? parseInt(b.minimumOffer) : Infinity
          return offerA - offerB
        })
        break
      case 'minimumOffer-high-to-low':
        itemsCopy.sort((a, b) => {
          const offerA = a.minimumOffer && a.isActive ? parseInt(a.minimumOffer) : -Infinity
          const offerB = b.minimumOffer && b.isActive ? parseInt(b.minimumOffer) : -Infinity
          return offerB - offerA
        })
        break
      case 'lastPrice-low-to-high':
        itemsCopy.sort((a, b) => {
          const priceA = a.lastPrice ? parseInt(a.lastPrice) : Infinity
          const priceB = b.lastPrice ? parseInt(b.lastPrice) : Infinity
          return priceA - priceB
        })
        break
      case 'lastPrice-high-to-low':
        itemsCopy.sort((a, b) => {
          const priceA = a.lastPrice ? parseInt(a.lastPrice) : -Infinity
          const priceB = b.lastPrice ? parseInt(b.lastPrice) : -Infinity
          return priceB - priceA
        })
        break
      default:
        itemsCopy.sort((a, b) => a.tokenId - b.tokenId)
        break
    }
    return itemsCopy
  }

  const handleSelectChange = selected => {
    setFilterOption(selected)
  }

  const fetchNFTs = async () => {
    try {
      setLoading(true)

      if (currentNetwork) {
        // @ts-ignore
        const _nfts = await sellsCallback()
        // _nfts = _nfts.sort(compareTokens)
        setTokenMarket(_nfts)
      }
    } catch (error) {
      console.error(error)
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  const sortedItems = async () => {
    try {
      setLoading(true)

      const _sortItems = sortItems(filterOption, tokenMarket)
      setTokenMarketFilter(_sortItems)
    } catch (error) {
      console.error(error)
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  const handlePageClick = event => {
    const newOffset = (event.selected * itemsPerPage) % tokenMarket?.length
    setItemOffset(newOffset)
  }

  const fetMarketInfo = async () => {
    const _marketInfo = await marketInfoCallback()
    setMarketInfo(_marketInfo)
  }

  useEffect(() => {
    fetMarketInfo()
    const interval = setInterval(() => {
      fetMarketInfo()
    }, 1000 * 60)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    fetchNFTs()
  }, [account, chainId, currentNetwork])

  useEffect(() => {
    sortedItems()
  }, [tokenMarket, filterOption])

  return (
    <Layout>
      <SectionWrapper>
        <Container>
          <Row>
            <Col style={{ marginBottom: '15px' }}>
              <PageTitle>Mystery Box</PageTitle>
              <PageSubTitle>CasperPunks</PageSubTitle>
            </Col>
            <Col xl={8}>
              <MarketInfo marketInfo={marketInfo} contractHash={currentNetwork?.contract?.Box} />
            </Col>
          </Row>
          <SoftWrap>
            <SortTitle>Sort By</SortTitle>
            {/*
              // @ts-ignore */}
            <Select options={options} value={filterOption} onChange={handleSelectChange} styles={customStyles} />
          </SoftWrap>
          {loading ? (
            <ContentWrapper>
              <Loader size="40px" />
            </ContentWrapper>
          ) : (
            <>
              {tokenMarketFilter?.length > 0 ? (
                <>
                  <Row style={{ marginTop: 30 }}>
                    {currentItems.map((nft, index) => (
                      <Col xs={12} md={6} lg={4} xl={3} key={index}>
                        <BoxWrap>
                          <AuctionBox
                            nft={nft}
                            isGen0={false}
                            showBtn={true}
                            connectWalletCallback={() => {
                              setShowConnectModal(true)
                            }}
                          />
                        </BoxWrap>
                      </Col>
                    ))}
                  </Row>
                  {/*
                  // @ts-ignore */}
                  <ReactPaginate
                    className="pagination"
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                  />
                </>
              ) : (
                <ContentWrapper>No Digital Collectibles found.</ContentWrapper>
              )}
            </>
          )}
        </Container>
        <ConnectModal show={showConnectModal} onHide={() => setShowConnectModal(false)} />
      </SectionWrapper>
    </Layout>
  )
}

export default MyteryBox
