import { useCallback } from 'react'
import axios from 'axios'
import { useCurrentNetwork } from './useNetwork'
import { CLPublicKey } from 'casper-js-sdk'

export const useTokenInfo = (
  tokenId: string | undefined | null,
  contractHash: string,
  isGen0: boolean,
): (() => Promise<any>) => {
  const currentNetwork = useCurrentNetwork()
  return useCallback(async (): Promise<any> => {
    try {
      if (tokenId) {
        const response = await axios.get(
          `${currentNetwork?.apiMake}/contract-packages/${
            isGen0
              ? currentNetwork?.contractGen0?.NFTPackageHash
              : contractHash === currentNetwork?.newGen1Contract?.NFT
              ? currentNetwork?.contract?.NFTPackageHash
              : currentNetwork?.contract?.BoxPackageHash
          }/nft-tokens/${tokenId}?fields=contract_package`,
        )

        if (response.status === 200 && response.data) {
          return response.data
        }
      }
    } catch (error: any) {
      console.error(error)
      return {}
    }
  }, [tokenId, contractHash, isGen0])
}

export const useNft = (account: string): (() => Promise<any>) => {
  const currentNetwork = useCurrentNetwork()
  let accountHash = CLPublicKey.fromHex(account).toAccountHashStr()
  accountHash = accountHash.split('-')[2]
  return useCallback(async (): Promise<any> => {
    try {
      const response = await axios.get(
        `${currentNetwork?.apiMake}/accounts/${accountHash}/nft-tokens?fields=contract_package&page=1&limit=1000`,
      )

      if (response.status === 200 && response.data) {
        return response.data
      }
    } catch (error: any) {
      console.error(error)
      return {}
    }
  }, [accountHash])
}
