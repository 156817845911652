import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components/macro'
import { CasperClient, CLByteArray, CLPublicKey, CLValueBuilder, DeployUtil, RuntimeArgs } from 'casper-js-sdk'
import { useActiveWeb3React, useCurrentNetwork, useTimestamp, useTopBid } from 'hooks'
import { useTransactionAdder } from 'state/transactions/hooks'
import { useAccount, useConnectorId, useWalletProvider } from 'state/wallet/hooks'
import Modal from 'components/Modal'
import Button from 'components/Button'
import { createRecipientAddress } from 'casper-js-client-helper/dist/helpers/lib'
import BigNumber from 'bignumber.js'
import axios from 'axios'
import { getDeployFunction, priceBid } from '../../utils'
import TransactionConfirmationModal from 'components/TransactionConfirmationModal'

const StyledModal = styled(Modal)`
  .modal-body {
    padding: 0 2.5rem 3.75rem;
  }
`

const InputWrapper = styled.div`
  margin: 4rem 0 2rem;
`

const ModalSubTitle = styled.p`
  font-weight: 400;
  font-size: 20px;
  color: #191820;
  margin-bottom: 2rem;
  margin-top: 1rem;
  text-align: center;
`

const InputField = styled.fieldset`
  position: relative;
  margin-bottom: 1rem;

  label {
    position: absolute;
    font-weight: 100;
    font-size: 14px;
    line-height: 22px;
    right: 1.25rem;
    top: 50%;
    transform: translateY(-50%);
  }

  input {
    border: 1px solid #e5e5e5;
    width: 100%;
    font-size: 14px;
    line-height: 22px;
    border-radius: 24px;
    padding: 11px 21px;

    &:focus {
      outline: none !important;
      border-color: #b9b8bb;
    }
  }
`

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #565660;
    margin-bottom: 0;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

interface IBidNFTModalProps {
  nft: any
  id: string
  totalBid: number
  show: boolean
  onHide: () => void
  isGen0: boolean
  nftContract: string
  marketContract: string
}

function BidNFTModal(props: IBidNFTModalProps): JSX.Element {
  const { nft, show, onHide, id, totalBid, isGen0, nftContract, marketContract } = props

  const { connector } = useActiveWeb3React()
  const account = useAccount()
  const timestampCallback = useTimestamp()
  const currentNetwork = useCurrentNetwork()
  const connectorId = useConnectorId()
  const provider = useWalletProvider()
  const bidCallback = useTopBid(nft?.tokenId ? nft?.tokenId : id, nft?.nftContract, isGen0)

  // const [fee, setFee] = useState<number>(8 / 100)
  const [bidAmount, setBidAmount] = useState('')
  const [topBid, setTopBid] = useState<number | string>()

  const addTransaction = useTransactionAdder()
  const [showConfirm, setShowConfirm] = useState(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [txHash, setTxHash] = useState('')
  const [isActive, setActive] = useState<boolean>(false)
  const [isDoneSell, setDoneSell] = useState<boolean>(false)

  const onDismiss = () => {
    setAttemptingTxn(false)
    onHide()
  }

  const onBid = async () => {
    try {
      setShowConfirm(true)
      setAttemptingTxn(true)
      let gasFee = 38000000000

      if (account && currentNetwork) {
        const _gasFee = await axios.get(
          `${
            isGen0 ? currentNetwork.urlApiGen0 : currentNetwork.urlApi
          }/getfee?isDoneSell=${isDoneSell}&txType=bid&totalBid=${totalBid}`,
        )
        if (_gasFee.status === 200 && _gasFee.data.gasFee) {
          // eslint-disable-next-line prefer-destructuring
          gasFee = _gasFee.data.gasFee
        }
        // const value = Number(bidAmount) + fee
        const value = new BigNumber(bidAmount).times(1e9).toFixed(0).toString()

        const senderKey = CLPublicKey.fromHex(account)
        const timestamp = await timestampCallback()
        const deployParams = new DeployUtil.DeployParams(
          senderKey,
          currentNetwork?.key ?? 'casper-test',
          1,
          1800000,
          [],
          timestamp,
        )
        const nftContractHash = new CLByteArray(Uint8Array.from(Buffer.from(nftContract, 'hex')))
        const nftMarketHash = new CLByteArray(Uint8Array.from(Buffer.from(marketContract, 'hex')))

        const runtimeArgs = RuntimeArgs.fromMap({
          deposit_entry_point_name: CLValueBuilder.string('bid'),
          amount: CLValueBuilder.u512(value),
          //@ts-ignore
          marketplace_hash: createRecipientAddress(nftMarketHash),
          //@ts-ignore
          nft_contract_hash: createRecipientAddress(nftContractHash),
          token_id: CLValueBuilder.string(nft?.tokenId ? nft?.tokenId : id),
          //@ts-ignore
          bidder: createRecipientAddress(CLPublicKey.fromHex(account)),
        })

        const response = await axios.get(isGen0 ? '/payment_contract-gen0.wasm' : '/payment_contract.wasm', {
          responseType: 'arraybuffer',
        })

        const instance = new Uint8Array(Buffer.from(response.data, 'binary'))

        const deploy = DeployUtil.makeDeploy(
          deployParams,
          DeployUtil.ExecutableDeployItem.newModuleBytes(instance, runtimeArgs),
          DeployUtil.standardPayment(gasFee),
        )

        if (deploy && provider) {
          const json = DeployUtil.deployToJson(deploy)
          const casperClient = new CasperClient(currentNetwork.rpcURL)
          const hash = await getDeployFunction(account, casperClient, connectorId, deploy, provider, json, connector)

          if (hash) {
            try {
              await addTransaction(hash, {
                summary: `Bid ${bidAmount} CSPR #${nft?.tokenId ? nft?.tokenId : id}.`,
              })

              setTxHash(hash)
              setAttemptingTxn(false)
              onDismiss()
            } catch (error: any) {
              console.error(error)
              setShowConfirm(false)
              setAttemptingTxn(false)
              toast.error(error)
            }
          }
        }
      }
    } catch (error) {
      console.error(error)
      setShowConfirm(false)
      setAttemptingTxn(false)
    }
  }

  useEffect(() => {
    Number(bidAmount) >= Number(currentNetwork?.minPrice) ? setActive(true) : setActive(false)
  }, [bidAmount])

  // @ts-ignore
  useEffect(async () => {
    const response = await bidCallback()
    console.log('response', response)
    const _bid = response ? priceBid(response) : currentNetwork?.minPrice
    setTopBid(_bid)
  }, [nft])

  // @ts-ignore
  useEffect(async () => {
    const minimumOffer = new BigNumber(nft?.minimumOffer).div(1e9).toNumber()
    if (Number(bidAmount) >= minimumOffer) {
      setDoneSell(true)
    } else {
      setDoneSell(false)
    }
  }, [nft, bidAmount])

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    setAttemptingTxn(false)
    setTxHash('')
  }, [txHash])

  return (
    <>
      <StyledModal show={show} title="Place A Bid" onHide={onDismiss}>
        <ModalSubTitle>You must list above {topBid} CSPR</ModalSubTitle>
        <InputWrapper>
          <InputField>
            <input value={bidAmount} onChange={e => setBidAmount(e.target.value)} />
            <label>CSPR</label>
          </InputField>
          {/*<InfoRow>*/}
          {/*  <p>Service fee:</p>*/}
          {/*  <p>{fee} CSPR</p>*/}
          {/*</InfoRow>*/}
          <InfoRow>
            <p>Total bid amount:</p>
            <p>{bidAmount} CSPR</p>
          </InfoRow>
        </InputWrapper>
        <ButtonWrapper>
          <Button type="primary" loading={attemptingTxn} handleClick={onBid} disabled={!isActive}>
            Bid
          </Button>
        </ButtonWrapper>
      </StyledModal>
      <TransactionConfirmationModal
        isOpen={showConfirm}
        title="Place Bid"
        attemptingTxn={attemptingTxn}
        hash={txHash}
        pendingText=""
        onDismiss={handleDismissConfirmation}
        content={() => <></>}
      />
    </>
  )
}

export default BidNFTModal
