import { Container } from 'react-bootstrap'
import { ContentText, MetaTitle, MetaSubTitle, SectionWrapper, TitleWrapper } from './Styled'
import { SectionTitle } from '../../components/Section2'

function FAQ(): JSX.Element {
  return (
    <SectionWrapper>
      <Container>
        <TitleWrapper>
          <SectionTitle>FAQ</SectionTitle>
          <MetaTitle>Frequently Asked Questions On CasperPunks Digital Collectibles</MetaTitle>
          <MetaSubTitle>
            There will only ever be 10,000 CasperPunks in existence from the Generation 1 series and only 100 super-rare
            from the 2021 Generation 0 series.
          </MetaSubTitle>
        </TitleWrapper>
        <ol>
          <li>
            <ContentText>
              <span>I already own a Gen 0 CasperPunk!</span> If you are one of the few lucky winners of a Gen 0
              CasperPunk you will automatically be able to mint a Gen 1 CasperPunk in this new series. Your Gen 0
              CasperPunk will remain the rarest of them all.
              <br />
              <br />
            </ContentText>
          </li>
          <li>
            <ContentText>
              <span>How do I join the whitelist!</span> Start by whitelisting your valid CSPR address, email, discord
              and twitter to be added to the whitelist, there is a limited number of whitelist places and you will be
              notified in the near future if you have been successfully chosen. Further instructions will also be sent
              at that time on the minting date and next steps.
              <br />
              <br />
            </ContentText>
          </li>
          <li>
            <ContentText>
              <span>How will I know I have a successful whitelist place!</span> Once you have submitted all your
              information correctly you will receive an email in December informing you if you have been successful
              along with next step instructions to prepare for minting.
              <br />
              <br />
            </ContentText>
          </li>
          <li>
            <ContentText>
              <span>How many CasperPunks will be available!</span> There will only ever be 10,000 CasperPunks in
              existence from the Generation 1 series and only 100 super-rare from the 2021 Generation 0 series.
              <br />
              <br />
            </ContentText>
          </li>
          <li>
            <ContentText>
              <span>Community!</span> There is only one official Discord server group to bring together all the
              beautiful and lovely CasperPunk community participants,{' '}
              <a href="https://discord.gg/casperpunks" target="_blank" rel="noreferrer">
                join it here.
              </a>
              <br />
              <br />
            </ContentText>
          </li>
          <li>
            <ContentText>
              <span>Roadmap!</span> The current roadmap is made available on the CasperPunk website and will be evolved
              as the community grows.
              <br />
              <br />
            </ContentText>
          </li>
          <li>
            <ContentText>
              <span>IP Rights!</span> You have a lot of freedom to bring to life your own individual CapserPunk with
              certain rights to be announced for individual owners.
              <br />
              <br />
            </ContentText>
          </li>
          <li>
            <ContentText>
              <span>CasperPunk Utility!</span> Outside of your CasperPunk being a Digital Collectible, you will be able
              to trade, breed and evolve it. And coming soon as part of the Roadmap you will be able to game, access
              CasperPunk partnerships, commercialize it and gain early access to some Casper ecosystem projects.
              <br />
              <br />
            </ContentText>
          </li>
          <li>
            <ContentText>
              <span>GhostDAO!</span> In the near future the CasperPunk team as stewards of the community will slowly
              move out of the way and make the core community at the heart of evolving the direction of CasperPunks. To
              do so a DAO structure will be implemented and individuals able to participate.
              <br />
              <br />
            </ContentText>
          </li>
          <li>
            <ContentText>
              <span>Marketplace!</span> Coming soon the marketplace will give you a way to share and interact with other
              collectors.
              <br />
              <br />
            </ContentText>
          </li>
          <li>
            <ContentText>
              <span>CasperPunks Project:</span> The goal of this project is to drive innovation around dynamic NFTs and
              core technology as a whole on the Casper Network and then as rapidly as possible open source the
              technology to the community to foster and accelerate a flourishing ecosystem. Beyond initial launch the
              longer term goal of CasperPunks is to make this a community driven initiative and platform for continuous
              innovation. CasperPunks was launched by the Punk Games team, DotOracle and welcomed by the wider
              community.
              <br />
              <br />
            </ContentText>
          </li>
        </ol>
      </Container>
    </SectionWrapper>
  )
}

export default FAQ
