import { useState, useEffect, useCallback } from 'react'
import { Container, Tab, Row, Col } from 'react-bootstrap'
import styled from 'styled-components/macro'
import { useAccount, useConnectorId, useWalletProvider } from 'state/wallet/hooks'
import { useActiveWeb3React, useBids, useCurrentNetwork, useMetadata, useNFTs, useSells, useTimestamp } from 'hooks'
import SectionTitle from 'components/SectionTitle'
import Loader from 'components/Loader'
import { NFT2 } from 'type/NFT'
import NFTBox from './NFTBox'
import StyledTab from 'components/Tab'
import NFTItem from 'components/NFTItem'
import Button from 'components/Button'
// import BidNFTModal from 'components/BidNFTModal'
import {
  CasperClient,
  CLByteArray,
  CLPublicKey,
  CLValueBuilder,
  decodeBase16,
  DeployUtil,
  RuntimeArgs,
} from 'casper-js-sdk'
import { toast } from 'react-toastify'
import { useTransactionAdder } from 'state/transactions/hooks'
import IncreaseBidNFTModal from 'components/IncreaseBidNFTModal'
import ChangePriceNFTModal from 'components/ChangePriceNFTModal'
import TransactionConfirmationModal from 'components/TransactionConfirmationModal'
import Layout from 'components/Layout'
import { CiWallet } from 'react-icons/ci'
import ConnectModal from '../../components/ConnectButton/ConnectModal'

import { getDeployFunction } from 'utils'
import { CEP78Client } from 'casper-cep78-js-client'
import NFTBoxNew from './NFTBoxNew'
import Select from 'react-select'

const SectionWrapper = styled.div`
  padding: 5rem 0;

  @media (min-width: 992px) {
    padding: 2.5rem 0;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  margin-top: 5rem;
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-height: 137px;

  button {
    width: 100%;
  }
`

const SoftWrap = styled.div`
  display: flex;
  min-height: 44px;
  padding: 0 30px;
  justify-content: end;
  align-items: center;
  gap: 16px;
`

const SortTitle = styled.h4`
  color: #000000;
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 500;
  line-height: 40px;
`

const options = [
  { value: 'gen0', label: 'Gen0 CasperPunks' },
  { value: 'gen1', label: 'Gen1 CasperPunks' },
  { value: 'mysteryBox', label: 'Mystery Box' },
]

function MyBoxes(): JSX.Element {
  const [nfts, setNFTs] = useState<NFT2[]>([])
  const [missingNFTs, setMissingNFTs] = useState<number[] | null>()
  const [bids, setBids] = useState<any>([])
  const [sells, setSells] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [isFetchingSells, setFetchingSells] = useState(false)
  const [isFetchingBids, setFetchingBids] = useState(false)
  const [currentNFT, setCurrentNFT] = useState<any>()
  const [showBidModal, setShowBidModal] = useState(false)
  const [showChangePriceModal, setShowChangePriceModal] = useState(false)
  const [showConnectModal, setShowConnectModal] = useState(false)
  const [filterOption, setFilterOption] = useState(options.find(option => option.value === 'gen1'))

  const addTransaction = useTransactionAdder()
  const [showConfirm, setShowConfirm] = useState(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [txHash, setTxHash] = useState('')
  const [title, setTitle] = useState('')
  const [accountHash, setAccountHash] = useState<string>('')
  const [isGen0, setGen0] = useState(false)

  const { connector } = useActiveWeb3React()
  const account = useAccount()
  const connectorId = useConnectorId()
  const provider = useWalletProvider()
  const network = useCurrentNetwork()
  const timestampCallback = useTimestamp()

  const currentNetwork = useCurrentNetwork()
  const [valueMarket, setValueMarket] = useState('')

  const contractHashProps =
    valueMarket == 'gen0'
      ? currentNetwork?.contractGen0?.NFT
      : valueMarket == 'mysteryBox'
      ? currentNetwork?.contract?.Box
      : currentNetwork?.newGen1Contract?.NFT

  const marketContractProps =
    valueMarket == 'gen0'
      ? currentNetwork?.contractGen0.Market
      : valueMarket == 'mysteryBox'
      ? currentNetwork?.contract.Market
      : currentNetwork?.newGen1Contract.Market

  const contractPackageHashProps =
    valueMarket == 'gen0'
      ? currentNetwork?.contractGen0.NFTPackageHash
      : valueMarket == 'mysteryBox'
      ? currentNetwork?.contract.BoxPackageHash
      : currentNetwork?.newGen1Contract.NFTPackageHash

  const nftsCallback = useNFTs(accountHash, contractPackageHashProps)
  const bidsCallback = useBids(account, isGen0, contractHashProps)
  const sellsCallback = useSells(account, isGen0, contractHashProps)
  const metadataCallback = useMetadata(isGen0)
  const onChooseBid = (nft: any) => {
    setShowBidModal(true)
    setCurrentNFT(nft)
  }

  const onChooseChangePrice = (nft: any) => {
    setShowChangePriceModal(true)
    setCurrentNFT(nft)
  }

  const onCancelSell = async (nft: any) => {
    try {
      setShowConfirm(true)
      setAttemptingTxn(true)
      setTitle('Cancel Sell')

      if (account && currentNetwork) {
        // @ts-ignore
        const senderKey = CLPublicKey.fromHex(account)
        const timestamp = await timestampCallback()
        const deployParams = new DeployUtil.DeployParams(
          senderKey,
          currentNetwork?.key ?? 'casper-test',
          1,
          1800000,
          [],
          timestamp,
        )
        // @ts-ignore
        const contractHashAsByteArray = decodeBase16(marketContractProps)
        const nftContractHash = new CLByteArray(Uint8Array.from(Buffer.from(contractHashProps, 'hex')))
        const deploy = DeployUtil.makeDeploy(
          deployParams,
          DeployUtil.ExecutableDeployItem.newStoredContractByHash(
            contractHashAsByteArray,
            'revoke_sell',
            RuntimeArgs.fromMap({
              token_id: CLValueBuilder.string(nft.tokenId),
              nft_contract_hash: CLValueBuilder.key(nftContractHash),
            }),
          ),
          DeployUtil.standardPayment(35000000000),
        )

        if (deploy && provider) {
          const json = DeployUtil.deployToJson(deploy)
          const casperClient = new CasperClient(currentNetwork?.rpcURL)
          const hash = await getDeployFunction(account, casperClient, connectorId, deploy, provider, json, connector)

          if (hash) {
            try {
              await addTransaction(hash, {
                summary: `Cancel sell #${nft.tokenId}.`,
              })

              setTxHash(hash)
              setAttemptingTxn(false)
            } catch (error: any) {
              console.error(error)
              setShowConfirm(false)
              setAttemptingTxn(false)
              toast.error(error)
            }
          }
        }
      }
    } catch (error) {
      console.error(error)
      setShowConfirm(false)
      setAttemptingTxn(false)
    }
  }

  const onCancelBid = async (nft: any) => {
    try {
      setShowConfirm(true)
      setAttemptingTxn(true)
      setTitle('Cancel Bid')

      if (account && currentNetwork) {
        // @ts-ignore
        const senderKey = CLPublicKey.fromHex(account)
        const timestamp = await timestampCallback()
        const deployParams = new DeployUtil.DeployParams(
          senderKey,
          currentNetwork?.key ?? 'casper-test',
          1,
          1800000,
          [],
          timestamp,
        )
        // @ts-ignore
        const contractHashAsByteArray = decodeBase16(marketContractProps)
        const nftContractHash = new CLByteArray(Uint8Array.from(Buffer.from(contractHashProps, 'hex')))
        const deploy = DeployUtil.makeDeploy(
          deployParams,
          DeployUtil.ExecutableDeployItem.newStoredContractByHash(
            contractHashAsByteArray,
            'revoke_bid',
            RuntimeArgs.fromMap({
              token_id: CLValueBuilder.string(nft.tokenId),
              nft_contract_hash: CLValueBuilder.key(nftContractHash),
            }),
          ),
          DeployUtil.standardPayment(13000000000),
        )

        if (deploy && provider) {
          const json = DeployUtil.deployToJson(deploy)
          const casperClient = new CasperClient(currentNetwork.rpcURL)
          const hash = await getDeployFunction(account, casperClient, connectorId, deploy, provider, json, connector)

          if (hash) {
            try {
              await addTransaction(hash, {
                summary: `Cancel bid #${nft.tokenId}.`,
              })

              setTxHash(hash)
              setAttemptingTxn(false)
            } catch (error: any) {
              console.error(error)
              setShowConfirm(false)
              setAttemptingTxn(false)
              toast.error(error)
            }
          }
        }
      }
    } catch (error) {
      console.error(error)
      setShowConfirm(false)
      setAttemptingTxn(false)
    }
  }

  const fetchNFTs = async () => {
    let _nfts = await nftsCallback()
    if (valueMarket == 'gen1') {
      _nfts = _nfts.map(nft => {
        nft.image = `https://api-box.casperpunks.io/metadata/images/${nft?.tokenId}.png`
        return nft
      })
    }
    setNFTs(_nfts)

    if (_nfts?.length === 0 && filterOption === options.find(option => option.value === 'mysteryBox')) {
      try {
        if (network && account) {
          setLoading(true)
          const _cep78Client = await CEP78Client.createInstance(
            contractHashProps,
            network.rpcURL,
            network.key ?? 'casper-test',
          )
          const _tokenName = await _cep78Client.getOwnedTokenIds(CLPublicKey.fromHex(account))
          setMissingNFTs(_tokenName)
        }
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    }
  }

  const getBids = async () => {
    try {
      setFetchingBids(true)
      let _bids = await bidsCallback()
      let metaDataGen0 = []
      if (isGen0) {
        metaDataGen0 = await metadataCallback()
      }
      if (valueMarket == 'gen1') {
        _bids = _bids.map(bid => {
          bid.image = `https://api-box.casperpunks.io/metadata/images/${bid?.tokenId}.png`
          return bid
        })
      }
      if (_bids?.length > 0) {
        let result = [
          ...[..._bids, ...metaDataGen0]
            .reduce((acc, curr) => acc.set(curr.tokenId, { ...acc.get(curr.tokenId), ...curr }), new Map())
            .values(),
        ]
        result = result.filter(e => e.biddingPrice !== undefined)
        setBids(result)
      } else {
        setBids([])
      }
    } catch (error) {
      console.error(error)
    } finally {
      setFetchingBids(false)
    }
  }

  const getSells = async () => {
    try {
      setFetchingSells(true)
      let _sells = await sellsCallback()
      let metaDataGen0 = []
      if (isGen0) {
        metaDataGen0 = await metadataCallback()
      }
      if (valueMarket == 'gen1') {
        _sells = _sells.map(sell => {
          sell.image = `https://api-box.casperpunks.io/metadata/images/${sell?.tokenId}.png`
          return sell
        })
      }
      if (_sells?.length > 0) {
        let result = [
          ...[..._sells, ...metaDataGen0]
            .reduce((acc, curr) => acc.set(curr.tokenId, { ...acc.get(curr.tokenId), ...curr }), new Map())
            .values(),
        ]
        result = result.filter(e => e.minimumOffer !== undefined)
        setSells(result)
      } else {
        setSells([])
      }
    } catch (error) {
      console.error(error)
    } finally {
      setFetchingSells(false)
    }
  }

  const getAccountHash = async () => {
    if (account) {
      const _account = CLPublicKey.fromHex(account).toAccountHashStr()
      const _splitAccountHex = _account.split('-')
      setAccountHash(_splitAccountHex[2])
    }
  }

  useEffect(() => {
    getAccountHash()
  }, [account])

  useEffect(() => {
    setMissingNFTs(null)
    fetchNFTs()
  }, [accountHash, account, isGen0, contractHashProps])

  useEffect(() => {
    getSells()
    getBids()
    const interval = setInterval(() => {
      getSells()
      getBids()
    }, 1000 * 45)

    return () => clearInterval(interval)
  }, [account, isGen0, contractHashProps])

  useEffect(() => {
    //@ts-ignore
    setValueMarket(filterOption?.value)
    if (filterOption?.value === 'gen0') {
      setGen0(true)
    } else {
      setGen0(false)
    }
  }, [filterOption])

  // const getNFTsFromContract = async () => {
  //   try {
  //     if (network && account) {
  //       const _cep78Client = await CEP78Client.createInstance(
  //         network.contract?.Box,
  //         network.rpcURL,
  //         network.key ?? 'casper-test',
  //       )
  //       const _tokenName = await _cep78Client.getOwnedTokenIds(CLPublicKey.fromHex(account))
  //       setMissingNFTs(_tokenName)
  //       console.log('_tokenName', _tokenName)
  //     }
  //   } catch (e) {
  //     console.error(e)
  //   }
  // }
  //
  // useEffect(() => {
  //   getNFTsFromContract()
  // }, [account])

  const handleSelectChange = selected => {
    setFilterOption(selected)
  }

  const customStyles = {
    container: provided => ({
      ...provided,
      borderRadius: '4px',
      padding: '8px',
      background: 'transparent',
    }),
    control: (control, state) => ({
      ...control,
      background: state.isFocused ? '#fff' : 'transparent',
      borderColor: state.hover ? 'red' : 'none',
      borderWidth: '0',
      minWidth: '185px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
  }

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    setAttemptingTxn(false)
    setTxHash('')
  }, [txHash])

  // @ts-ignore
  return (
    <Layout>
      <SectionWrapper>
        <Container>
          <Row style={{ marginBottom: '30px' }}>
            <Col>
              <SectionTitle color="dark" mb={false}>
                Portfolio
              </SectionTitle>
            </Col>
            <Col>
              <SoftWrap>
                <SortTitle>Sort By</SortTitle>
                {/*
              // @ts-ignore */}
                <Select options={options} value={filterOption} onChange={handleSelectChange} styles={customStyles} />
              </SoftWrap>
            </Col>
          </Row>

          {account ? (
            <>
              <StyledTab defaultActiveKey="myBox">
                <Tab eventKey="myBox" title="My Box">
                  {nfts?.length > 0 ? (
                    <Row>
                      {nfts?.map((nft, index) => (
                        <Col key={index} xs={12} sm={6} lg={4} xl={3}>
                          <NFTBox nft={nft} isGen0={isGen0} nftContract={contractHashProps} />
                        </Col>
                      ))}
                    </Row>
                  ) : (
                    <>
                      {loading ? (
                        <ContentWrapper>
                          <Loader size="40px" />
                        </ContentWrapper>
                      ) : (
                        <>
                          {/*
                          // @ts-ignore */}
                          {missingNFTs?.length > 0 && !isGen0 ? (
                            <Row>
                              {missingNFTs?.map((nft, index) => (
                                <Col key={index} xs={12} sm={6} lg={4} xl={3}>
                                  <NFTBoxNew nft={nft} nftContract={contractHashProps} />
                                </Col>
                              ))}
                            </Row>
                          ) : (
                            <ContentWrapper>No Digital Collectible found.</ContentWrapper>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Tab>
                <Tab title="Sells" eventKey="sells">
                  {sells?.length > 0 ? (
                    <Row>
                      {sells?.map((nft, index) => (
                        <Col key={index} xs={12} sm={6} lg={4} xl={3}>
                          <NFTItem
                            nftContractPackageHash={currentNetwork?.newGen1Contract.NFTPackageHash}
                            nft={nft}
                            isGen0={isGen0}
                            children={
                              <ButtonWrapper onClick={e => e.stopPropagation()}>
                                <Button
                                  type="primary"
                                  loading={attemptingTxn}
                                  handleClick={() => onChooseChangePrice(nft)}
                                >
                                  Change Price
                                </Button>
                                <Button type="outline" loading={attemptingTxn} handleClick={() => onCancelSell(nft)}>
                                  Cancel Sell
                                </Button>
                              </ButtonWrapper>
                            }
                          />
                        </Col>
                      ))}
                    </Row>
                  ) : (
                    <>
                      {isFetchingSells ? (
                        <ContentWrapper>
                          <Loader size="40px" />
                        </ContentWrapper>
                      ) : (
                        <ContentWrapper>No selling Digital Collectible found.</ContentWrapper>
                      )}
                    </>
                  )}
                </Tab>
                <Tab title="Bids" eventKey="bids">
                  {bids?.length > 0 ? (
                    <Row>
                      {bids?.map((nft, index) => (
                        <Col key={index} xs={12} sm={6} lg={4} xl={3}>
                          <NFTItem
                            nftContractPackageHash={currentNetwork?.newGen1Contract.NFTPackageHash}
                            nft={nft}
                            isGen0={isGen0}
                            children={
                              <ButtonWrapper onClick={e => e.stopPropagation()}>
                                <Button type="primary" loading={attemptingTxn} handleClick={() => onChooseBid(nft)}>
                                  Increase Bid
                                </Button>
                                <Button type="outline" loading={attemptingTxn} handleClick={() => onCancelBid(nft)}>
                                  Cancel Bid
                                </Button>
                              </ButtonWrapper>
                            }
                          />
                        </Col>
                      ))}
                    </Row>
                  ) : (
                    <>
                      {isFetchingBids ? (
                        <ContentWrapper>
                          <Loader size="40px" />
                        </ContentWrapper>
                      ) : (
                        <ContentWrapper>No bidding Digital Collectible found.</ContentWrapper>
                      )}
                    </>
                  )}
                </Tab>
              </StyledTab>
              <IncreaseBidNFTModal
                nft={currentNFT}
                show={showBidModal}
                onHide={() => setShowBidModal(false)}
                isGen0={isGen0}
                nftContract={contractHashProps}
                marketContract={marketContractProps}
              />
              <ChangePriceNFTModal
                nft={currentNFT}
                show={showChangePriceModal}
                onHide={() => setShowChangePriceModal(false)}
                isGen0={isGen0}
                nftContract={contractHashProps}
                marketContract={marketContractProps}
              />
              <TransactionConfirmationModal
                isOpen={showConfirm}
                title={title}
                attemptingTxn={attemptingTxn}
                hash={txHash}
                pendingText=""
                onDismiss={handleDismissConfirmation}
                content={() => <></>}
              />
            </>
          ) : (
            <>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button type="primary" handleClick={() => setShowConnectModal(true)}>
                  <CiWallet size={20} />
                  Connect Wallet
                </Button>
              </div>
              <ConnectModal show={showConnectModal} onHide={() => setShowConnectModal(false)} />
            </>
          )}
        </Container>
      </SectionWrapper>
    </Layout>
  )
}

export default MyBoxes
