import { useCallback } from 'react'
import axios from 'axios'
import { useCurrentNetwork } from './useNetwork'

export const useCSPRPrice = (): (() => Promise<number>) => {
  const currentNetwork = useCurrentNetwork()
  return useCallback(async (): Promise<number> => {
    let result = 0

    try {
      const response = await axios.get(`${currentNetwork?.urlApi}/price`)

      if (response.status === 200 && response.data) {
        result = response.data.casper
      }
    } catch (error: any) {
      console.error(error)
    }
    return result
  }, [])
}
