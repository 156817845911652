import styled from 'styled-components/macro'

export const SectionWrapper = styled.div`
  padding: 5rem 0;

  @media (min-width: 992px) {
    padding: 2.5rem 0;
  }
`

export const ContentWrapper = styled.div`
  padding: 1rem 0;

  @media (min-width: 992px) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 3rem;
  }
`

export const InfoWrapper = styled.div`
  margin-top: 2rem;

  @media (min-width: 992px) {
    margin-top: 0;
  }

  .tab-content {
    margin-left: 1rem;
    margin-bottom: 2rem;
  }
`

export const ItemImage = styled.img`
  @media (min-width: 992px) {
    width: 25rem;
  }

  @media (min-width: 1200px) {
    width: 30rem;
  }

  @media (min-width: 1400px) {
    width: 38rem;
  }
`

export const DefaultImage = styled.div`
  position: relative;
  width: 38rem;

  img {
    width: 100%;
  }
`

export const ItemName = styled.div`
  margin-bottom: 12px;
  color: #000;
  font-weight: 500;
  font-size: 25px;
  line-height: 40px;
`

export const Description = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
  margin-bottom: 35px;
`

export const BidWrapper = styled.div`
  p {
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    color: #000;
    margin-right: 5px;
    margin-bottom: 0;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 20px;
  margin-top: 30px;
`
