import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useCurrentNetwork } from 'hooks'
import styled from 'styled-components/macro'
import Button from 'components/Button'
import { ButtonWrapper } from './index'
import TransferNFTModal from 'components/TransferNFTModal'
import LootBox from 'assets/images/loot-box.png'

const NFTBoxWrapper = styled.div`
  display: grid;
  gap: 0.5rem;
  background: #fff;
  border: 1px solid #e5e5e5;
  box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
  border-radius: 15px;
  padding: 0.5rem 1.5rem 1.5rem;
  width: fit-content;
  margin: auto;
  margin-bottom: 2rem;
  transition: all 0.5s ease;

  &:hover {
    cursor: pointer;
    transform: translateY(-0.75rem);
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;

    img:not(#creator) {
      transform: scale(1.1);
      transition: all 0.5s ease;
    }
  }
`

const NFTName = styled.span`
  position: relative;
  font-weight: 300;
  font-size: 22px;
  line-height: 40px;
  z-index: 1;
`

const NFTImage = styled.div`
  position: relative;
  overflow: hidden;
`

const InfoWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 0.5rem 0;
`

const InfoTitle = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #b9b8bb;
  margin-right: 5px;
  margin-bottom: 0;
`

const CreatorName = styled.span`
  font-size: 14px;
  font-weight: 500;
`

interface INFTBoxProps {
  nft: any
  nftContract: string
}

function NFTBoxNew(props: INFTBoxProps): JSX.Element {
  const { nft, nftContract } = props
  const currentNetwork = useCurrentNetwork()
  const history = useHistory()

  const [showTransferModal, setShowTransferModal] = useState(false)
  const pushHistory = () => {
    history.push({ pathname: `/details/${nftContract}/${nft}` })
  }
  const pushRedeem = () => {
    history.push(`/redeem/${nft}`)
  }

  return (
    <NFTBoxWrapper>
      <div className="d-flex justify-content-between align-items-center">
        <NFTName>#{nft}</NFTName>
      </div>
      <NFTImage>
        <img
          src={
            nftContract == currentNetwork?.newGen1Contract.NFT
              ? `https://api-box.casperpunks.io/metadata/images/${nft}.png`
              : LootBox
          }
          alt=""
        />
      </NFTImage>
      <InfoWrapper>
        <InfoTitle>Collection</InfoTitle>
        <CreatorName>Casper Punk</CreatorName>
      </InfoWrapper>
      <ButtonWrapper onClick={e => e.stopPropagation()}>
        {nftContract === currentNetwork?.contract.Box && (
          <Button type="primary" handleClick={pushRedeem}>
            Redeem
          </Button>
        )}
        <Button type="primary" handleClick={pushHistory}>
          Sell
        </Button>
        <Button type="outline" handleClick={() => setShowTransferModal(true)}>
          Transfer
        </Button>
      </ButtonWrapper>
      {showTransferModal && (
        <TransferNFTModal
          nft={nft}
          nftContract={nftContract}
          show={showTransferModal}
          onHide={() => setShowTransferModal(false)}
        />
      )}
    </NFTBoxWrapper>
  )
}

export default NFTBoxNew
