import { useState } from 'react'
import { Container } from 'react-bootstrap'
import { HeaderMain, HeaderWrapper, Logo, LogoWrap, MenuToggle } from './Styled'
import Navigation from '../Navigation'
import LogoLight from 'assets/images/logo-light.svg'
import { FiMenu } from 'react-icons/fi'

function Header(): JSX.Element {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const handleMobileMenuOpen = () => {
    setMobileMenuOpen(true)
  }

  return (
    <HeaderMain>
      <Container>
        <HeaderWrapper>
          <LogoWrap to="/">
            <Logo src={LogoLight} />
          </LogoWrap>
          <Navigation isOpen={mobileMenuOpen} onHide={() => setMobileMenuOpen(false)} />
          <MenuToggle onClick={handleMobileMenuOpen}>
            <FiMenu size={24} />
          </MenuToggle>
        </HeaderWrapper>
      </Container>
    </HeaderMain>
  )
}

export default Header
