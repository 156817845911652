/* eslint-disable @typescript-eslint/no-extra-semi */
import { AbstractConnector } from '@web3-react/abstract-connector'
import { CasperWalletEventTypes, CasperWalletState, ConnectorNames, connectorsByName, torus } from 'connectors'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useActiveWeb3React } from 'hooks/useWeb3'
import { useWeb3React } from '@dotoracle/web3-react-core'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState } from 'state'
import { isDev } from 'utils'
import { updateConnector, updateAccount } from './actions'
import { ConnectorLocalStorageKey } from '../../constants'

export const useConnectorId = (): string => {
  let connectorId = window.localStorage.getItem(ConnectorLocalStorageKey)
  const currentConnector = useSelector((state: AppState) => state.wallet.connector)

  if (currentConnector !== undefined) {
    connectorId = currentConnector
  }

  return useMemo(() => connectorId ?? ConnectorNames.CasperSigner, [currentConnector])
}

export const useWalletProvider = (): any => {
  const connectorId = useConnectorId()
  const { connector: torusConnector } = useActiveWeb3React()

  switch (connectorId) {
    case ConnectorNames.CasperWallet:
      // @ts-ignore
      if (window.CasperWalletProvider !== undefined) {
        // @ts-ignore
        return window.CasperWalletProvider()
      }
      break
    case ConnectorNames.CasperDash:
      // @ts-ignore
      return window.casperDashHelper
    case ConnectorNames.TorusWallet:
      if (torusConnector) {
        // @ts-ignore
        const { torus: torusC } = torusConnector
        return torusC
      }
      break
    case ConnectorNames.CasperSigner:
    default:
      // @ts-ignore
      return window.casperlabsHelper
  }
}

export const useConnectWalletCallback = (): ((
  connectorID: ConnectorNames,
  connector?: AbstractConnector,
) => Promise<void>) => {
  const { activate, account: accountWeb3 } = useActiveWeb3React()
  const currentConnector = useConnectorId()
  const dispatch = useDispatch<AppDispatch>()
  let account = ''

  const callback = useCallback(
    async (connectorId: ConnectorNames): Promise<void> => {
      const _connector = connectorsByName[connectorId]
      try {
        switch (connectorId) {
          case ConnectorNames.CasperSigner:
            // @ts-ignore
            if (window.casperlabsHelper != undefined) {
              // @ts-ignore
              window.casperlabsHelper.requestConnection()
              try {
                window.addEventListener(
                  'signer:connected',
                  e => {
                    // @ts-ignore
                    dispatch(updateAccount({ account: e.detail.activeKey }))
                    window.removeEventListener('signer:connected', () => {}, false)
                  },
                  false,
                )
              } catch {}
              try {
                window.addEventListener(
                  'signer:unlocked',
                  e => {
                    // @ts-ignore
                    dispatch(updateAccount({ account: e.detail.activeKey }))
                    window.removeEventListener('signer:unlocked', () => {}, false)
                  },
                  false,
                )
              } catch {}
              //  @ts-ignore
              try {
                account = await window.casperlabsHelper.getActivePublicKey()
              } catch {}
            }
            break
          case ConnectorNames.TorusWallet:
            activate(torus, undefined, true)
            break
          case ConnectorNames.CasperWallet:
            // @ts-ignore
            if (window.CasperWalletProvider != undefined) {
              // @ts-ignore
              const { CasperWalletProvider } = window
              const provider = CasperWalletProvider()
              await provider.requestConnection()
              account = await provider.getActivePublicKey()
            }
            break
          case ConnectorNames.CasperDash:
            // @ts-ignore
            if (window.casperDashHelper != undefined) {
              // @ts-ignore
              await window.casperDashHelper.requestConnection()
              try {
                window.addEventListener(
                  'casperdash:connected',
                  e => {
                    // @ts-ignore
                    dispatch(updateAccount({ account: e.detail.activeKey }))
                    window.removeEventListener('casperdash:connected', () => {}, false)
                  },
                  false,
                )
              } catch {}
              try {
                window.addEventListener(
                  'casperdash:unlocked',
                  e => {
                    // @ts-ignore
                    dispatch(updateAccount({ account: e.detail.activeKey }))
                    window.removeEventListener('casperdash:unlocked', () => {}, false)
                  },
                  false,
                )
              } catch {}
              // @ts-ignore
              account = await window.casperDashHelper.getActivePublicKey()
            }
            break
          default:
            // @ts-ignore
            if (connectorId && window.ethereum) {
              activate(_connector)
              // @ts-ignore
              account = accountWeb3 ?? ''
            }
            break
        }
      } catch (error) {
        console.error(error)
      }

      localStorage.setItem(ConnectorLocalStorageKey, connectorId)
      dispatch(updateConnector({ connector: connectorId }))
      dispatch(updateAccount({ account }))
    },
    [currentConnector, account],
  )

  return callback
}

export const useEagerConnect = (): boolean => {
  const { activate, active } = useWeb3React()
  const [tried, setTried] = useState(false)
  const connectorId = useConnectorId()

  useEffect(() => {
    ;(async () => {
      try {
        switch (connectorId) {
          case ConnectorNames.TorusWallet:
            activate(torus, undefined, true).catch(() => {
              setTried(true)
            })
            break
          case ConnectorNames.CasperDash:
          case ConnectorNames.CasperSigner:
          case ConnectorNames.CasperWallet:
          default:
            setTried(true)
            break
        }
      } catch (error) {
        console.error(error)
      }
    })()
  }, [activate])

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (active) {
      setTried(true)
    }
  }, [active])

  return tried
}

export const useAccount = (): string | null | undefined => {
  const { account: accountWeb3 } = useActiveWeb3React()
  const [account, setAccount] = useState(accountWeb3)
  const connectorId = useConnectorId()

  const dispatch = useDispatch<AppDispatch>()
  const currentAccount = useSelector((state: AppState) => state.wallet.account)

  useEffect(() => {
    ;(async () => {
      try {
        switch (connectorId) {
          case ConnectorNames.CasperSigner:
            // @ts-ignore
            if (window.casperlabsHelper !== undefined) {
              try {
                // @ts-ignore
                const _account = await window.casperlabsHelper.getActivePublicKey()
                setAccount(_account)
              } catch (error) {
                setAccount('')
              }
            }
            break
          case ConnectorNames.CasperDash:
            // @ts-ignore
            if (window.casperDashHelper !== undefined) {
              try {
                // @ts-ignore
                const _account = await window.casperDashHelper.getActivePublicKey()
                setAccount(_account)
              } catch (error) {
                setAccount('')
              }
            }
            break
          case ConnectorNames.CasperWallet:
            //@ts-ignore
            if (window.CasperWalletProvider != undefined) {
              const _account = currentAccount
              setAccount(_account ?? '')

              const handleConnected = (event: any) => {
                try {
                  const state: CasperWalletState = JSON.parse(event.detail)
                  if (state.activeKey) {
                    setAccount(state.activeKey)
                    dispatch(updateAccount({ account: state.activeKey }))
                  }
                } catch (err) {
                  setAccount('')
                }
              }

              const handleDisconnected = (event: any) => {
                try {
                  const state: CasperWalletState = JSON.parse(event.detail)
                  if (!state.isConnected) {
                    setAccount('')
                  }
                } catch (err) {
                  setAccount('')
                }
                dispatch(updateAccount({ account: '' }))
              }

              const handleLocked = () => {
                setAccount('')
                dispatch(updateAccount({ account: '' }))
              }

              window.addEventListener(CasperWalletEventTypes.Connected, handleConnected)
              window.addEventListener(CasperWalletEventTypes.ActiveKeyChanged, handleConnected)
              window.addEventListener(CasperWalletEventTypes.Unlocked, handleConnected)

              window.addEventListener(CasperWalletEventTypes.Disconnected, handleDisconnected)
              window.addEventListener(CasperWalletEventTypes.Locked, handleLocked)

              return () => {
                window.removeEventListener(CasperWalletEventTypes.Connected, handleConnected)
                window.removeEventListener(CasperWalletEventTypes.ActiveKeyChanged, handleConnected)
                window.removeEventListener(CasperWalletEventTypes.Unlocked, handleConnected)

                window.removeEventListener(CasperWalletEventTypes.Disconnected, handleDisconnected)
                window.removeEventListener(CasperWalletEventTypes.Locked, handleLocked)
              }
            }
            break
          default:
            setAccount(accountWeb3 ?? '')
            break
        }
      } catch (error) {
        setAccount('')
        console.error(error)
      }
    })()
  }, [accountWeb3, connectorId, currentAccount])

  return useMemo(() => account, [account])
}

export const useDeactivateCallback = (): (() => Promise<void>) => {
  const connectorId = useConnectorId()
  const { deactivate } = useActiveWeb3React()
  const account = useAccount()
  const dispatch = useDispatch<AppDispatch>()

  const callback = useCallback(async (): Promise<void> => {
    switch (connectorId) {
      case ConnectorNames.CasperWallet:
        // @ts-ignore
        if (window.CasperWalletProvider != undefined) {
          // @ts-ignore
          const { CasperWalletProvider } = window
          const provider = CasperWalletProvider()
          await provider.disconnectFromSite()
        }
        break
      case ConnectorNames.TorusWallet:
        deactivate()
        break
      case ConnectorNames.CasperSigner:
        if (window.casperlabsHelper != undefined) {
          // @ts-ignore
          window.casperlabsHelper.disconnectFromSite()
        }
        break
      case ConnectorNames.CasperDash:
        // @ts-ignore
        if (window.casperDashHelper != undefined) {
          // @ts-ignore
          window.casperDashHelper.disconnectFromSite()
        }
        break
      default:
        break
    }

    dispatch(updateConnector({ connector: '' }))
    dispatch(updateAccount({ account: '' }))
  }, [connectorId, account])

  return callback
}

export const useChainId = (): number => {
  const { chainId: web3ChainId } = useActiveWeb3React()
  const connectorId = useConnectorId()
  const sourceNetwork = useSelector((state: AppState) => state.application.sourceNetwork)
  let chainId = sourceNetwork?.chainId ?? 131614895977472

  switch (connectorId) {
    case ConnectorNames.CasperSigner:
    case ConnectorNames.CasperWallet:
    case ConnectorNames.CasperDash:
      chainId = isDev ? 96945816564243 : 131614895977472
      break
    case ConnectorNames.TorusWallet:
      chainId = web3ChainId ?? 96945816564243
      break
  }

  return useMemo(() => chainId, [chainId])
}
