import React from 'react'
import styled from 'styled-components/macro'
import { useCopyClipboard } from 'hooks'
import { MdContentCopy } from 'react-icons/md'
import { BiCheckCircle } from 'react-icons/bi'

const CopyIcon = styled.div`
  color: #565660;
  cursor: pointer;
  flex-shrink: 0;
  display: flex;
  text-decoration: none;
  font-size: 0.825rem;
  transition: all 0.5s ease;

  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
    color: #181920;
  }

  svg {
    display: inline-block;
    vertical-align: middle;
  }
`
const TransactionStatusText = styled.span`
  display: flex;
  align-items: center;
  margin-left: 0.25rem;
  font-size: 0.825rem;
`

export default function CopyHelper(props: { toCopy: string; children?: React.ReactNode }): JSX.Element {
  const [isCopied, setCopied] = useCopyClipboard()

  return (
    <CopyIcon onClick={() => setCopied(props.toCopy)}>
      {isCopied ? (
        <TransactionStatusText>
          <BiCheckCircle size={18} />
          <TransactionStatusText>Copied</TransactionStatusText>
        </TransactionStatusText>
      ) : (
        <TransactionStatusText>
          <MdContentCopy size={18} />
        </TransactionStatusText>
      )}
      {isCopied ? '' : props.children}
    </CopyIcon>
  )
}
