import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Web3ReactManager from 'components/Web3ReactManager'
import Header from 'components/Header'
import HeaderSecond from './components/HeaderSecond'
import Footer from './components/Footer'
import HomePage from './pages/HomePage'
import ItemDetails from './pages/ItemDetails'
// import CreateItem from 'pages/CreateItem'
import MyBoxes from 'pages/MyBoxes'
import MysteryBox from 'pages/MysteryBox'
import ConnectWallet from 'pages/ConnectWallet'
import RedeemMysteryBox from 'pages/RedeemMysteryBox'
import AttributePage from 'pages/Attribute'

import 'react-toastify/dist/ReactToastify.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'assets/fonts/stylesheet.css'
import 'assets/scss/style.scss'
import Popups from 'components/Popups'
import Gen0Boxes from './pages/Gen0Boxes'
import NewGen1Nft from './pages/NewGen1Nft'
import TermOfUse from './pages/TermOfUse'
import PrivacyPolicy from './pages/PrivacyPolicy'
import FAQ from './pages/FAQ'
import CookiePolicy from './pages/CookiePolicy'

// eslint-disable-next-line
function App() {
  return (
    <Web3ReactManager>
      <Router>
        <Header />
        <HeaderSecond />
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route exact strict path="/details/:contractHash/:id">
            <ItemDetails />
          </Route>
          {/*<Route exact strict path="/create">*/}
          {/*  <CreateItem />*/}
          {/*</Route>*/}
          <Route exact strict path="/portfolio">
            <MyBoxes />
          </Route>
          <Route exact strict path="/gen0boxes">
            <Gen0Boxes />
          </Route>
          <Route exact strict path="/mysterybox">
            <MysteryBox />
          </Route>
          <Route exact strict path="/gen1boxes">
            <NewGen1Nft />
          </Route>
          <Route exact strict path="/connectwallet">
            <ConnectWallet />
          </Route>
          <Route exact strict path="/redeem/:tokenId">
            <RedeemMysteryBox />
          </Route>
          <Route exact strict path="/attributes">
            <AttributePage />
          </Route>
          <Route exact strict path="/termofuse">
            <TermOfUse />
          </Route>
          <Route exact strict path="/policy">
            <PrivacyPolicy />
          </Route>
          <Route exact strict path="/faq">
            <FAQ />
          </Route>
          <Route exact strict path="/cookiepolicy">
            <CookiePolicy />
          </Route>
        </Switch>
        <Footer />
        <Popups />
      </Router>
    </Web3ReactManager>
  )
}

export default App
