import styled from 'styled-components/macro'

export const Wrap = styled.div<{ border?: string; maxWidth?: string; borderColor?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 30px;
  border: ${({ border }) => (border ? border : '2px solid #34c05b')};
  border-color: ${({ borderColor }) => (borderColor ? borderColor : '')};
  background-color: white;
  padding: 0.3rem;
  padding-right: 1rem;
  @media (min-width: 768px) {
    padding-right: 1.5rem;
  }
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : '382px')};
`
export const WrapImageAtt = styled.div`
  background-color: #676767;
  border-radius: 22px;
  height: 122.86px;
  width: 122.86px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const WrapText = styled.div`
  flex-direction: column;
  display: flex;
  padding-left: 1rem;
  justify-content: center;
`
export const Text = styled.p<{ fontSize: string; lineHeight: string; width?: string; textAlign?: string }>`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: ${({ fontSize }) => fontSize};
  line-height: ${({ lineHeight }) => lineHeight};
  letter-spacing: -0.02em;
  color: #000000;
  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'left')};
  margin-bottom: 0.3rem;
  margin-top: 0.1rem;
  max-width: 180px;
  width: ${({ width }) => (width ? width : '')};
`
export const Line = styled.div`
  border: 1px solid rgba(103, 103, 103, 0.2);
  width: 100%;
  height: 100%;
  margin-bottom: 0.1rem;
`
export const WrapText2 = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
