import { Tabs } from 'react-bootstrap'
import styled from 'styled-components/macro'

export const StyledTabs = styled(Tabs)`
  width: auto;
  justify-content: start;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #e5e5e5;

  .nav-item {
    position: relative;
    margin-right: 21px;
    padding-right: 8px;
    padding-bottom: 10px;
  }

  .nav-link {
    position: relative;
    font-size: 17px;
    line-height: 22px;
    color: #000000;
    border: none;
    padding: 0;

    &:hover {
      color: #191820;
    }
  }

  .nav-link.active {
    font-weight: 500;

    &::after {
      content: '';
      position: absolute;
      background-color: #ad203e;
      width: 100%;
      height: 2px;
      bottom: -10px;
      left: 0;
    }
  }
`

interface ITabProps {
  defaultActiveKey: string
  children?: string | JSX.Element | JSX.Element[]
}

function StyledTab(props: ITabProps): JSX.Element {
  const { children, defaultActiveKey } = props
  return (
    <StyledTabs defaultActiveKey={defaultActiveKey} transition={false}>
      {children}
    </StyledTabs>
  )
}

export default StyledTab
