import { useCallback } from 'react'
import axios from 'axios'
import { useCurrentNetwork } from './useNetwork'
import { CLPublicKey } from 'casper-js-sdk'
export const useNFTs = (
  accountHash: string | undefined | null,
  nftcontractPackageHash: boolean,
): (() => Promise<any>) => {
  const currentNetwork = useCurrentNetwork()
  return useCallback(async (): Promise<any> => {
    try {
      if (accountHash) {
        const response = await axios.get(
          `${currentNetwork?.apiCSPRLive}/accounts/${accountHash}/nft-tokens?page=1&limit=1000`,
        )

        if (response.status === 200 && response.data) {
          const _res = response.data.data
          return _res.filter(nft => {
            return nft.contract_package_hash === nftcontractPackageHash
          })
        }
      }
      return []
    } catch (error: any) {
      console.error(error)
      return []
    }
  }, [accountHash, nftcontractPackageHash])
}

export const useRedeemNFTs = (account: string | undefined | null): (() => Promise<any>) => {
  const currentNetwork = useCurrentNetwork()

  const getAccountHash = () => {
    if (account) {
      const _account = CLPublicKey.fromHex(account).toAccountHashStr()
      const _splitAccountHex = _account.split('-')
      return _splitAccountHex[2]
    }
  }
  return useCallback(async (): Promise<any> => {
    try {
      const accountHash = getAccountHash()
      if (accountHash) {
        const response = await axios.get(
          `${currentNetwork?.apiCSPRLive}/accounts/${accountHash}/nft-tokens?page=1&limit=1000`,
        )

        if (response.status === 200 && response.data) {
          const _res = response.data.data
          return _res.filter(nft => {
            return nft.contract_package_hash === currentNetwork?.contract.NFTPackageHash
          })
        }
      }
    } catch (error: any) {
      console.error(error)
      return []
    }
  }, [account])
}
