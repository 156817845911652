import { Wrap, WrapImageAtt, WrapText, Line, WrapText2, Text } from './Style'
import MockAttAvatar from 'assets/images/Attributes/Grey$100 1.png'
import MockAttAvatar2 from 'assets/images/Attributes/Grey$5.png'

export enum ColorAttribute {
  Common = '#34C05B',
  Rare = '#00A3FF',
  Epic = '#C32BD0',
  Legendary = '#FFB800',
}

interface Attribute {
  border?: string
  maxWidth?: string
  type: string
  name: string
  quantity: string
  hasAttribute?: boolean
  hasBackground?: boolean
}

export default function Attribute(props: Attribute): JSX.Element {
  const { border, maxWidth, type, name, quantity, hasAttribute, hasBackground } = props
  return (
    <Wrap border={border} maxWidth={maxWidth} borderColor={ColorAttribute[type]}>
      <WrapImageAtt>
        {hasBackground && name !== 'Grey' && (
          <img src={`${process.env.PUBLIC_URL}/images/${name}.png`} height={'100%'} alt="" />
        )}
        <img
          src={name == 'Glowing' ? MockAttAvatar2 : MockAttAvatar}
          height="auto"
          style={{ position: 'absolute' }}
          alt=""
        />
        {hasAttribute && name != 'None' && (
          <img
            src={`${process.env.PUBLIC_URL}/images/${name}.png`}
            height="auto"
            style={{ position: 'absolute' }}
            alt=""
          />
        )}
      </WrapImageAtt>
      <WrapText>
        <Text fontSize={'14px'} lineHeight={'17px'}>
          {name ? name : 'Mock'}
        </Text>
        <Line />
        <WrapText2>
          <Text fontSize={'12px'} lineHeight={'15px'}>
            # of CasperPunks with Attribute
          </Text>
          <Text fontSize={'28px'} lineHeight={'47px'} width="48px" textAlign="right">
            {quantity}
          </Text>
        </WrapText2>
        <Line />
        <Text fontSize={'12px'} lineHeight={'15px'}>
          Rarity: <span style={{ color: type ? ColorAttribute[type] : ColorAttribute.Common }}>{type}</span>
        </Text>
      </WrapText>
    </Wrap>
  )
}
