import { useCallback } from 'react'
import axios from 'axios'
import { useCurrentNetwork } from './useNetwork'

export const useMetadata = (isGen0: boolean): (() => Promise<any>) => {
  const currentNetwork = useCurrentNetwork()
  return useCallback(async (): Promise<any> => {
    try {
      if (isGen0) {
        const response = await axios.get(`${isGen0 ? currentNetwork?.urlApiGen0 : currentNetwork?.urlApi}/gettokens`)

        if (response.status === 200 && response.data.tokens) {
          return response.data.tokens
        }
      }
    } catch (error: any) {
      console.error(error)
      return []
    }
  }, [isGen0])
}

export const useNFTMetadata = (tokenId: string, isGen0: boolean): (() => Promise<any>) => {
  const currentNetwork = useCurrentNetwork()
  return useCallback(async (): Promise<any> => {
    try {
      const response = await axios.get(`${isGen0 ? currentNetwork?.urlApiGen0 : currentNetwork?.urlApi}/gettokens`)

      if (response.status === 200 && response.data.tokens) {
        const { tokens } = response.data
        const _metadata = tokens.filter(token => token.tokenId === tokenId)
        return _metadata[0]
      }
    } catch (error: any) {
      console.error(error)
      return {}
    }
  }, [])
}

export const useGen1Metadata = (tokenId: string, contractHash: string, isGen0): (() => Promise<any>) => {
  const currentNetwork = useCurrentNetwork()
  return useCallback(async (): Promise<any> => {
    try {
      if (isGen0) return
      if (contractHash == currentNetwork?.contract.Box) return
      const response = await axios.get(`${currentNetwork?.urlApi}/metadata/${tokenId}`)
      if (response.status === 200 && response.data) {
        return response.data
      }
    } catch (error: any) {
      console.error(error)
      return
    }
  }, [tokenId, contractHash])
}
