import styled from 'styled-components/macro'

export const WrapImageAtt = styled.div`
  background-color: #676767;
  border-radius: 6px;
  height: 37px;
  width: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
`
