import { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { useHistory } from 'react-router-dom'
import { useCSPRPrice } from 'hooks'
import { toCSPR, toInternationalCurrencySystemNature } from 'utils'
import { Col, Row } from 'react-bootstrap'

export type Info = {
  totalVolume: string
  floorPrice: string
  bestOffer: string
  listedPercentage: string
  bestBidTokenId: string | undefined | null
  floorBidTokenId: string | undefined | null
}

interface MarketInfoProps {
  marketInfo: Info
  contractHash: string
}

const MarketInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  span:first-child {
    font-size: 22px;
    font-weight: 500;
  }

  span:last-child {
    opacity: 0.7;
    font-weight: 300;
  }
`

const toPrice = (number: string | undefined | null, price: number): string => {
  if (number) {
    return Number(Number(toCSPR(number)) * price).toFixed(2)
  } else {
    return '0'
  }
}

const MarketInfo = ({ marketInfo, contractHash }: MarketInfoProps): ReactElement => {
  const [csprPrice, setCSPRPrice] = useState<number>(0)
  const history = useHistory()
  const csprPriceCallback = useCSPRPrice()

  const fetchPrice = async () => {
    const _price = await csprPriceCallback()
    setCSPRPrice(_price)
  }

  const pushHistory = (tokenId: string | null | undefined) => {
    if (tokenId) {
      history.push({ pathname: `/details/${contractHash}/${tokenId}` })
    }
  }

  useEffect(() => {
    fetchPrice()
  }, [])

  return (
    <>
      <Row>
        <Col xs={6} md={3}>
          <MarketInfoItem>
            <span>{toInternationalCurrencySystemNature(toCSPR(marketInfo?.totalVolume), 1)}</span>
            <span>~${toPrice(marketInfo?.totalVolume, csprPrice)}</span>
            <span>Total Volume</span>
          </MarketInfoItem>
        </Col>
        <Col xs={6} md={3}>
          <MarketInfoItem style={{ cursor: 'pointer' }} onClick={() => pushHistory(marketInfo.floorBidTokenId)}>
            <span>{toInternationalCurrencySystemNature(toCSPR(marketInfo?.floorPrice), 1)}</span>
            <span>~${toPrice(marketInfo?.floorPrice, csprPrice)}</span>
            <span>Floor Price</span>
          </MarketInfoItem>
        </Col>
        <Col xs={6} md={3}>
          <MarketInfoItem style={{ cursor: 'pointer' }} onClick={() => pushHistory(marketInfo.bestBidTokenId)}>
            <span>{toInternationalCurrencySystemNature(toCSPR(marketInfo?.bestOffer), 1)}</span>
            <span>~${toPrice(marketInfo?.bestOffer, csprPrice)}</span>
            <span>Best Offer</span>
          </MarketInfoItem>
        </Col>
        <Col xs={6} md={3}>
          <MarketInfoItem>
            <span style={{ fontSize: '40px' }}>{marketInfo?.listedPercentage ? marketInfo?.listedPercentage : 0}%</span>
            <span>Listed</span>
          </MarketInfoItem>
        </Col>
      </Row>
    </>
  )
}

export default MarketInfo
