import { createReducer } from '@reduxjs/toolkit'
import { NFT } from 'type/NFT'
import NFTCollection from 'type/NFTCollection'
import { clearNFTCache, updateNFTCollection, updateNFTList, updateSelectedNFTs } from './actions'

export interface NFTState {
  readonly nftCollection?: NFTCollection
  readonly nftList: NFT[]
  readonly selectedNFTs: NFT[]
}

const initialState: NFTState = {
  nftCollection: undefined,
  nftList: [],
  selectedNFTs: [],
}

export default createReducer(initialState, builder =>
  builder
    .addCase(updateNFTCollection, (state, action) => {
      state.nftCollection = action.payload.nftCollection
    })
    .addCase(updateNFTList, (state, action) => {
      state.nftList = action.payload.nftList
    })
    .addCase(updateSelectedNFTs, (state, action) => {
      state.selectedNFTs = action.payload.selectedNFTs
    })
    .addCase(clearNFTCache, () => {
      return initialState
    }),
)
