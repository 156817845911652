import styled from 'styled-components/macro'
import { Container } from 'react-bootstrap'

export const SectionWrapper = styled(Container)`
  padding: 5rem 0;

  @media (min-width: 992px) {
    padding: 2.5rem 0;
  }
`

export const TextHeader = styled.div<{ fontWeight?: number }>`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 700)};
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin-left: 1rem;
`

export const TextTitle = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #000000;
  padding: 0rem 0.5rem;
`
export const WrapTitle = styled.div`
  background: #f5f5f5;
  padding: 0.5rem 1rem;
  margin: 3rem 0rem;
`
export const WrapAttribute = styled.div`
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 0rem 0.5rem;
`
