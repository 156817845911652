/* eslint-disable @typescript-eslint/no-extra-semi */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHasPendingApproval, useTransactionAdder } from 'state/transactions/hooks'
import { useAccount, useConnectorId, useWalletProvider } from 'state/wallet/hooks'
import { useCurrentNetwork } from './useNetwork'
import {
  CasperClient,
  CLByteArray,
  CLKey,
  CLBool,
  CLPublicKey,
  decodeBase16,
  DeployUtil,
  RuntimeArgs,
  CLValueBuilder,
  CasperServiceByJsonRPC,
} from 'casper-js-sdk'
import { toast } from 'react-toastify'
import { CEP78Client } from 'casper-cep78-js-client'
import { createRecipientAddress } from 'casper-js-client-helper/dist/helpers/lib'
import { getDeployFunction } from 'utils'
import { useActiveWeb3React } from './useWeb3'
import { useTimestamp } from './useTimestamp'
import { CEP47Client } from 'casper-cep47-js-client'
export enum NFTApprovalState {
  UNKNOWN = 'UNKNOWN',
  NOT_APPROVED = 'NOT_APPROVED',
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
}

export const useApproveNFTCasperCallback = (
  contractHash: string,
  tokenIds: string[],
  spender: string,
  isGen0: boolean,
  marketContractPackageHash: string,
): [NFTApprovalState, () => Promise<void>, boolean] => {
  const { connector } = useActiveWeb3React()
  const account = useAccount()
  const addTransaction = useTransactionAdder()
  const pendingApproval = useHasPendingApproval(contractHash, spender)
  const network = useCurrentNetwork()
  const connectorId = useConnectorId()
  const provider = useWalletProvider()
  const timestampCallback = useTimestamp()

  const [loadingCheckApprove, setLoadingCheckApprove] = useState(false)
  const [approved, setApproved] = useState(false)
  const [tokenName, setTokenName] = useState<any>()
  useEffect(() => {
    ;(async () => {
      try {
        if (network && contractHash && account && tokenIds) {
          setLoadingCheckApprove(true)
          if (isGen0) {
            const _cep47Client = new CEP47Client(network.rpcURL, network.key ?? 'casper-test')
            _cep47Client.setContractHash(`hash-${contractHash}`)
            const _tokenName = await _cep47Client.name()
            setTokenName(_tokenName)

            const allowance = await _cep47Client.getAllowance(CLPublicKey.fromHex(account), tokenIds[0])
            const allowanceReplace = allowance.replace('account-hash-', '')
            setApproved(allowanceReplace == marketContractPackageHash ? true : false)
          } else {
            const _cep78Client = await CEP78Client.createInstance(
              contractHash,
              network.rpcURL,
              network.key ?? 'casper-test',
            )

            const _tokenName = _cep78Client.collectionName()
            setTokenName(_tokenName)

            const _operator = await _cep78Client.checkOperatorDictionaryKey(account, spender)
            setApproved(_operator)
          }
        }
      } catch (e) {
        setApproved(false)
        console.error(' checkAllow', e)
      }
      setLoadingCheckApprove(false)
    })()
  }, [account, contractHash, pendingApproval])

  const approvalState: NFTApprovalState = useMemo(() => {
    if (!account) return NFTApprovalState.UNKNOWN
    return !approved
      ? pendingApproval
        ? NFTApprovalState.PENDING
        : NFTApprovalState.NOT_APPROVED
      : NFTApprovalState.APPROVED
  }, [account, contractHash, approved, spender, pendingApproval])

  const approve = useCallback(async (): Promise<void> => {
    if (approvalState !== NFTApprovalState.NOT_APPROVED) {
      console.error('approve nft was called unnecessarily')
      return
    }

    if (!contractHash) {
      console.error('no contract')
      return
    }

    if (!spender) {
      console.error('no spender')
      return
    }

    if (account && network) {
      try {
        const timestamp = await timestampCallback()
        const deployParams = new DeployUtil.DeployParams(
          CLPublicKey.fromHex(account),
          network?.key ?? 'casper-test',
          1,
          1800000,
          [],
          timestamp,
        )
        let runtimeArgs
        let deploy
        if (isGen0) {
          // spender approve is contractPackageHash
          runtimeArgs = RuntimeArgs.fromMap({
            spender: new CLKey(new CLByteArray(decodeBase16(marketContractPackageHash))),
            token_ids: CLValueBuilder.list([CLValueBuilder.string(tokenIds[0])]),
          })
          deploy = DeployUtil.makeDeploy(
            deployParams,
            DeployUtil.ExecutableDeployItem.newStoredContractByHash(decodeBase16(contractHash), 'approve', runtimeArgs),
            DeployUtil.standardPayment(12000000000),
          )
        } else {
          runtimeArgs = RuntimeArgs.fromMap({
            //@ts-ignore
            token_owner: createRecipientAddress(CLPublicKey.fromHex(account)),
            approve_all: new CLBool(true),
            operator: new CLKey(new CLByteArray(decodeBase16(spender))),
          })

          deploy = DeployUtil.makeDeploy(
            deployParams,
            DeployUtil.ExecutableDeployItem.newStoredContractByHash(
              decodeBase16(contractHash),
              'set_approval_for_all',
              runtimeArgs,
            ),
            DeployUtil.standardPayment(5000000000),
          )
        }
        if (deploy && provider) {
          const json = DeployUtil.deployToJson(deploy)
          const casperClient = new CasperClient(network.rpcURL)
          const hash = await getDeployFunction(account, casperClient, connectorId, deploy, provider, json, connector)
          if (hash) {
            try {
              await addTransaction(hash, {
                summary: `Approve ${tokenName ?? 'NFT'}`,
                approval: { tokenAddress: contractHash, spender: spender },
              })
            } catch {}
          }
        }
      } catch (error) {
        console.error(error)
      }
    }
  }, [NFTApprovalState, account, contractHash, spender, addTransaction])

  return [approvalState, approve, loadingCheckApprove]
}
