import styled from 'styled-components/macro'

const DropdownWrap = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;

  &:hover {
    .dropdown-content {
      display: grid;
    }

    .dropdown-button {
      text-shadow: 0 0 1px #000;
      transition: all 0.5s ease;
    }
  }
`

const DropdownButton = styled.button`
  padding: 0;
  background: transparent;
  border: none;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
  text-decoration: none;
  color: #000;
`

const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: #e5e5e5;
  gap: 1rem;
  padding: 1rem;
  min-width: 185px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 99999;
`

interface IMenuDropDownProps {
  title: string
  children: React.ReactNode
}

function MenuDropdown({ title, children }: IMenuDropDownProps): JSX.Element {
  return (
    <DropdownWrap>
      <DropdownButton className="dropdown-button">{title}</DropdownButton>
      <DropdownContent className="dropdown-content">{children}</DropdownContent>
    </DropdownWrap>
  )
}

export default MenuDropdown
