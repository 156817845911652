import styled from 'styled-components/macro'

const StyledTitle = styled.h4<{ align?: string; color: string; mb?: boolean }>`
  text-align: ${({ align }) => (align == 'center' ? 'center' : 'left')};
  font-weight: 400;
  font-size: 34px;
  line-height: 40px;
  margin-bottom: ${({ mb }) => (mb === true ? '24px' : '8px')};
  color: ${({ color }) => (color == 'light' ? '#fff' : '#000')};
`

interface ITitleProps {
  align?: 'center'
  mb?: boolean
  color: 'light' | 'dark'
  children: React.ReactNode
}

function SectionTitle(props: ITitleProps): JSX.Element {
  const { align, color, children, mb } = props

  return (
    <StyledTitle align={align} color={color} mb={mb}>
      {children}
    </StyledTitle>
  )
}

export default SectionTitle

export const ThinSectionTitle = styled.h2`
  position: relative;
  font-weight: 300;
  font-size: 39px;
  line-height: 39px;
  color: #ecb049;
  z-index: 2;
  span {
    color: #fff;
  }

  @media (min-width: 768px) {
    font-size: 59px;
    line-height: 64px;
  }

  @media (min-width: 1920px) {
    font-size: 65px;
    line-height: 70px;
  }
`
export const ThinSectionTitleCongratulations = styled.h2`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 29px;
  line-height: 50px;
  /* or 125% */

  display: flex;
  align-items: center;

  span {
    color: #ffffff;
  }

  @media (min-width: 768px) {
    font-size: 35px;
    line-height: 40px;
  }

  @media (min-width: 1920px) {
    font-size: 40px;
    line-height: 70px;
  }
`
