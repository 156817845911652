import { createReducer } from '@reduxjs/toolkit'
import { updateConnector, updateAccount } from './actions'

export interface WalletState {
  readonly connector?: string
  readonly account?: string
}

export const initialState: WalletState = {
  connector: undefined,
  account: undefined,
}

export default createReducer(initialState, builder =>
  builder
    .addCase(updateConnector, (state, action) => {
      state.connector = action.payload.connector
    })
    .addCase(updateAccount, (state, action) => {
      state.account = action.payload.account
    }),
)
