import styled from 'styled-components/macro'
import { useNetworkInfo } from 'hooks'
import { useAllTransactions } from 'state/transactions/hooks'
import { useChainId } from 'state/wallet/hooks'
import { getExplorerLink } from 'utils'
import Loader from 'components/Loader'
import { IoAlertCircleOutline } from 'react-icons/io5'
import { BiCheckCircle } from 'react-icons/bi'
import ExternalLink from 'components/ExternalLink'

const TransactionWrapper = styled.div`
  margin-top: 0.25rem;
`

const TransactionStatusText = styled.div`
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  &:hover {
    text-decoration: underline;
  }
`

const TransactionState = styled(ExternalLink)<{ pending: boolean; success?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none !important;
  border-radius: 0.5rem;
  padding: 0.25rem 0rem;
  font-weight: 500;
  font-size: 0.825rem;

  svg {
    display: inline-block;
    vertical-align: middle;
  }

  span svg {
    margin-right: 0.5rem;
  }
`

const IconWrapper = styled.div<{ pending: boolean; success?: boolean }>`
  color: ${({ pending, success }) => (pending ? '#565660' : success ? '#27ae60' : '#ff6871')};
`

export default function Transaction({ hash }: { hash: string }): JSX.Element {
  const chainId = useChainId()
  const allTransactions = useAllTransactions()
  const tx = allTransactions?.[hash]
  const summary = tx?.summary
  const pending = !tx?.receipt
  const success = !pending && tx && (tx.receipt?.status === 1 || typeof tx.receipt?.status === 'undefined')

  const networkInfo = useNetworkInfo(chainId)

  return (
    <TransactionWrapper>
      <TransactionState href={getExplorerLink(networkInfo, hash, 'transaction')} pending={pending} success={success}>
        <div className="d-flex justify-content-center align-items-center" style={{ margin: 0 }}>
          <IconWrapper pending={pending} success={success}>
            {pending ? (
              <Loader stroke="#565660" />
            ) : success ? (
              <BiCheckCircle size={16} />
            ) : (
              <IoAlertCircleOutline size={16} />
            )}
          </IconWrapper>
          <TransactionStatusText>{summary ?? hash}</TransactionStatusText>
        </div>
      </TransactionState>
    </TransactionWrapper>
  )
}
