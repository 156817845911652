import styled from 'styled-components/macro'
import Button from 'components/Button'
import ProgressBar from 'react-bootstrap/ProgressBar'
import { Container } from 'react-bootstrap'

export const SectionWrapper = styled.section<{ bgImage: string }>`
  background-image: url(${({ bgImage }) => bgImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  padding: 10rem 0;

  @media (min-width: 768px) {
    padding: 15rem 0;
  }
`

export const StyledContainer = styled(Container)`
  @media (min-width: 992px) {
    display: block;
  }
`

export const SubTitle = styled.p`
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
`

export const StatusText = styled.p<{ color?: 'fail' | 'success' }>`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: ${({ color }) => (color === 'fail' ? '#ef0c3c' : color === 'success' ? '#FFFFFF' : '#ecb049')};
  margin-top: 4rem;

  @media (min-width: 768px) {
    font-size: 14px;
    line-height: 19px;
  }

  @media (min-width: 1200px) {
    font-size: 14px;
    line-height: 19px;
  }
`

export const ButtonWrap = styled.div`
  display: inline-grid;
  margin-top: 1rem;
  gap: 10px;
  @media (min-width: 576px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`

export const ConnectButton = styled(Button)`
  margin-bottom: 1.5rem;
  @media (min-width: 576px) {
    margin-bottom: 0;
    margin-right: 2rem;
  }
`
export const CancelButton = styled(Button)`
  margin-bottom: 1.5rem;
  @media (min-width: 576px) {
    margin-bottom: 0;
    margin-right: 2rem;
  }
`
export const StyledLink = styled.a`
  color: #fff;

  &:hover {
    color: #fff;
  }
`

export const StyledProgressBar = styled(ProgressBar)`
  &.progress {
    background-color: transparent !important;
    border: 1px solid #ecb049;
    border-radius: 0;
  }
`
export const WrapCongratulations = styled(Container)`
  justify-content: 'center';
  @media (min-width: 992px) {
    display: block;
  }
`
export const WrapText = styled.div`
  max-width: 413px;
`
export const WrapContentCongratulations = styled.div`
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid #ffffff;
  backdrop-filter: blur(8px);
  border-radius: 8px;

  @media (min-width: 576px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: 394px;
  }
  padding: 2rem 1rem;
`
export const LoadingCongratulations = styled.div`
  width: 50px;
  height: 100px;
  background: green;
`
export const WrapTextCongratulations = styled.div`
  margin-left: 1rem;
`
export const TextCongratulations = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  margin-top: 1rem;

  @media (min-width: 768px) {
    font-size: 14px;
    line-height: 19px;
  }

  @media (min-width: 1200px) {
    font-size: 14px;
    line-height: 19px;
  }
`
export const WrapImage = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
`

export const ContainerContentNft = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: row;
  min-width: 100%;
  @media (min-width: 768px) {
    min-width: 807px;
    min-height: 423px;
  }
`
export const WrapImagePortfolio = styled.div`
  display: flex;
  background-color: black;
  max-width: 52.3%;
  @media (min-width: 768px) {
    max-width: 423px;
    height: 423px;
  }
`
export const WrapImageAtt = styled.div`
  background-color: #676767;
  border-radius: 6px;
  height: 37px;
  width: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
`
export const WrapPortfolio = styled.div`
  background-color: black;
  padding: 1rem 1rem;
  max-width: 47%;
  @media (min-width: 768px) {
    max-width: 384px;
    height: 423px;
    padding: 3rem 3rem;
  }
`
export const TextNft = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;

  color: #ffffff;
  margin-top: 1rem;

  @media (min-width: 768px) {
    font-size: 31px;
    line-height: 34px;
  }

  // @media (min-width: 1200px) {
  //   font-size: 14px;
  //   line-height: 19px;
  // }
`
export const TextTokenId = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;

  color: #ffffff;
  margin-top: 1rem;

  @media (min-width: 768px) {
    font-size: 22px;
    line-height: 40px;
  }

  // @media (min-width: 1200px) {
  //   font-size: 14px;
  //   line-height: 19px;
  // }
`
export const TextAttributes = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: center;

  color: #ffffff;
  margin-top: 1rem;

  @media (min-width: 768px) {
    font-size: 17px;
    line-height: 20px;
  }
`
