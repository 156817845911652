import { IoAlertCircleOutline } from 'react-icons/io5'
import { BiCheckCircle } from 'react-icons/bi'
import styled from 'styled-components/macro'
import { useNetworkInfo } from 'hooks'
import { Col, Row } from 'react-bootstrap'
import { getExplorerLink } from 'utils'
import { useChainId } from 'state/wallet/hooks'
import ExternalLink from 'components/ExternalLink'

const LeftCol = styled(Col)`
  width: 15%;
  flex: 0 0 15%;
  padding-right: 0;
`
const RightCol = styled(Col)`
  width: 85%;
  flex: 0 0 85%;

  p {
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
`
export default function TransactionPopup({
  hash,
  success,
  summary,
}: {
  hash: string
  success?: boolean
  summary?: string
}): JSX.Element {
  const chainId = useChainId()
  const networkInfo = useNetworkInfo(chainId)

  return (
    <Row style={{ alignItems: 'center' }}>
      <LeftCol>
        {success ? <BiCheckCircle color="27ae60" size={30} /> : <IoAlertCircleOutline color="#ff6871" size={30} />}
      </LeftCol>
      <RightCol>
        <p>{summary ?? 'Hash: ' + hash.slice(0, 8) + '...' + hash.slice(58, 65)}</p>
        {chainId && (
          <ExternalLink href={getExplorerLink(networkInfo, hash, 'transaction')}>View on explorer</ExternalLink>
        )}
      </RightCol>
    </Row>
  )
}
