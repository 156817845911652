import Floater from 'react-floater'
import useWindowDimensions from 'hooks/useWindowDimensions'
import Attribute, { ColorAttribute } from 'components/Attribute'
import { capitalizeFirstLetter } from 'utils'
import { useEffect, useState } from 'react'
interface FloaterComponent {
  listAttribute: IAttribute[]
  name: string
  hasAttribute: boolean
  hasBackground: boolean
  children: React.ReactNode
}
interface IAttribute {
  rarity: string
  name: string
  count: string
}
export default function FloaterComponent(props: FloaterComponent): JSX.Element {
  const { children, name, listAttribute, hasAttribute, hasBackground } = props
  const { width } = useWindowDimensions()
  // const getAttribute = name => {}
  const [attribute, setAttribute] = useState<IAttribute>({ rarity: '???', name: '???', count: '???' })
  useEffect(() => {
    const attribute_ = listAttribute.filter((att: IAttribute) => {
      return att.name == name
    })[0]
    setAttribute(attribute_)
  }, [listAttribute])
  return (
    <Floater
      content={
        <Attribute
          border="none"
          type={capitalizeFirstLetter(attribute?.rarity || '???')}
          name={name}
          quantity={attribute?.count || '???'}
          maxWidth="none"
          hasAttribute={hasAttribute}
          hasBackground={hasBackground}
        />
      }
      disableHoverToClick
      hideArrow={true}
      event={width < 768 ? 'click' : 'hover'}
      eventDelay={0}
      placement="top"
      styles={{
        floater: {
          filter: 'none',
        },
        container: {
          //border color
          backgroundColor: ColorAttribute[capitalizeFirstLetter(attribute?.rarity || 'Common')],
          borderRadius: 30,
          padding: '2px 2px',
          width: width < 1000 ? (width < 576 ? 254 : 300) : 354,
        },
      }}
    >
      {children}
    </Floater>
  )
}
