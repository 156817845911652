import { Offcanvas } from 'react-bootstrap'
import { DropContentLink, MainMenu, MobileMenu, NavBarWrap, NavLink, StyledLink, StyledScrollLink } from './Styled'
import MenuDropdown from './MenuDropdown'

interface INavigationProps {
  darkMode?: boolean
  isOpen?: boolean
  onHide?: any
  footer?: boolean
}

function Navigation(props: INavigationProps): JSX.Element {
  const { darkMode, isOpen, onHide, footer } = props

  return (
    <MainMenu>
      {footer ? (
        <NavBarWrap className="d-lg-flex flex-grow-1" footer={footer}>
          <ul className="main-menu list-unstyled">
            <li>
              <NavLink to="/termofuse" darkMode={darkMode}>
                Term Of Use
              </NavLink>
            </li>
            <li>
              <NavLink to="/policy" darkMode={darkMode}>
                Privacy Policy
              </NavLink>
            </li>
            <li>
              <NavLink to="/faq" darkMode={darkMode}>
                FAQ
              </NavLink>
            </li>
          </ul>
        </NavBarWrap>
      ) : (
        <NavBarWrap className="d-none d-lg-flex flex-grow-1" footer={footer}>
          <ul className="main-menu list-unstyled">
            <li>
              <NavLink to="/portfolio" darkMode={darkMode}>
                Redeem Mystery Box
              </NavLink>
            </li>
            <li>
              <MenuDropdown title="Marketplace">
                <DropContentLink to="/gen0boxes">Gen 0</DropContentLink>
                <DropContentLink to="/mysterybox">Mystery Box</DropContentLink>
                <DropContentLink to="/gen1boxes">Gen 1</DropContentLink>
              </MenuDropdown>
            </li>
            <li>
              <StyledLink href="https://shop.casperpunks.io/" darkMode={darkMode} target="_blank">
                MERCH
              </StyledLink>
            </li>
            <li>
              <StyledScrollLink to="about" darkMode={darkMode} spy={true} smooth={true}>
                About
              </StyledScrollLink>
            </li>
            <li>
              <StyledScrollLink to="roadmap" darkMode={darkMode} spy={true} smooth={true}>
                Roadmap
              </StyledScrollLink>
            </li>

            <li>
              <NavLink to="/faq" darkMode={darkMode}>
                FAQ
              </NavLink>
            </li>
            <li>
              <StyledLink href="https://docs.casperpunks.io/" darkMode={darkMode} target="_blank">
                Docs
              </StyledLink>
            </li>
            <li>
              <StyledLink href="https://staking.casperpunks.io" darkMode={darkMode} target="_blank">
                Staking
              </StyledLink>
            </li>
            <li>
              <NavLink to="/connectwallet" darkMode={darkMode}>
                Create Wallet
              </NavLink>
            </li>
          </ul>
        </NavBarWrap>
      )}
      <Offcanvas show={isOpen} onHide={onHide}>
        <Offcanvas.Header closeButton />
        <Offcanvas.Body>
          <MobileMenu>
            <li>
              <StyledLink href="/" darkMode={darkMode} market>
                Marketplace
              </StyledLink>
            </li>
            <li>
              <StyledLink href="https://casperpunks.io/#about" darkMode={darkMode}>
                About
              </StyledLink>
            </li>
            <li>
              <StyledLink href="https://casperpunks.io/#roadmap" darkMode={darkMode}>
                Roadmap
              </StyledLink>
            </li>

            <li>
              <NavLink to="/faq" darkMode={darkMode}>
                FAQ
              </NavLink>
            </li>
            <li>
              <StyledLink href="https://docs.casperpunks.io/" darkMode={darkMode} target="_blank">
                Docs
              </StyledLink>
            </li>
            <li>
              <StyledLink href="https://staking.casperpunks.io" darkMode={darkMode} target="_blank">
                Staking
              </StyledLink>
            </li>
            <li>
              <StyledLink href="/connectwallet" darkMode={darkMode}>
                Create Wallet
              </StyledLink>
            </li>
          </MobileMenu>
        </Offcanvas.Body>
      </Offcanvas>
    </MainMenu>
  )
}

export default Navigation
