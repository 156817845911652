import React from 'react'
import styled from 'styled-components/macro'
import { useHistory } from 'react-router-dom'
import { formatPrice, ipfsURLConvert } from 'utils'
import { InfoTitle } from '../AuctionBox/Style'
import PriceTag from '../PriceTag'
import BigNumber from 'bignumber.js'
import LootBox from 'assets/images/loot-box.png'

const NFTItemWrap = styled.div`
  background: #ffffff;
  padding: 7px 28px 28px;
  border: 1px solid #cacaca;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.25);
  border-radius: 13px;
  cursor: pointer;
  transition: all 0.5s ease;

  &:hover {
    cursor: pointer;
    transform: translateY(-5px);
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;

    img:not(#creator) {
      transform: scale(1.1);
      transition: all 0.5s ease;
    }
  }
`

const Title = styled.h2`
  font-weight: 300;
  font-size: 22px;
  line-height: 40px;
  z-index: 1;
  color: #000;
`
const ImageNFT = styled.div`
  position: relative;
  overflow: hidden;
  margin-bottom: 5px;

  img {
    max-width: 100%;
  }
`

const NFTInfo = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 10px;
`

const InfoWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 0.5rem 0;
`

const CreatorName = styled.span`
  font-size: 14px;
  font-weight: 500;
`

interface INFTItemProps {
  nft: any
  children?: React.ReactNode
  showName?: boolean
  isGen0: boolean
  nftContractPackageHash: string
}

const NFTItem = (props: INFTItemProps): JSX.Element => {
  const { nft, children, showName, isGen0, nftContractPackageHash } = props
  const history = useHistory()
  const price = nft?.minimumOffer ? new BigNumber(nft?.minimumOffer).div(1e9).toString() : null
  const bidPrice = nft?.biddingPrice ? new BigNumber(nft?.biddingPrice).div(1e9).toString() : null
  const pushHistory = () => {
    history.push({ pathname: `/details/${nft.nftContract}/${nft.tokenId}` })
  }
  return (
    // @ts-ignore
    <NFTItemWrap onClick={() => pushHistory()}>
      <Title>#{nft?.tokenId}</Title>
      <ImageNFT>
        <img
          src={
            isGen0
              ? ipfsURLConvert(nft?.metadata?.asset ? nft?.metadata?.asset : nft?.image)
              : nft?.image
              ? `https://api-box.casperpunks.io/metadata/images/${nft?.tokenId}.png`
              : LootBox
          }
          alt={nft?.tokenId}
        />
      </ImageNFT>
      {showName && (
        <InfoWrapper>
          <InfoTitle>Collection</InfoTitle>
          <CreatorName>{nft?.collectionName}</CreatorName>
        </InfoWrapper>
      )}
      <NFTInfo>
        {price && (
          <>
            <InfoTitle>Price: </InfoTitle>
            <PriceTag price={price} symbol="CSPR" />
          </>
        )}
        {bidPrice && (
          <>
            <InfoTitle>Bidding: </InfoTitle>
            <PriceTag price={formatPrice(Number(bidPrice))} symbol="CSPR" />
          </>
        )}
      </NFTInfo>
      {children}
    </NFTItemWrap>
  )
}

export default NFTItem
