import { useCurrentNetwork } from './useNetwork'
import { useCallback } from 'react'
import axios from 'axios'

export const useMarketInfo = (isGen0: boolean, contractHash?: string | undefined): (() => Promise<any>) => {
  const currentNetwork = useCurrentNetwork()
  return useCallback(async (): Promise<any> => {
    try {
      let url
      if (contractHash) {
        url = `${isGen0 ? currentNetwork?.urlApiGen0 : currentNetwork?.urlApi}/gettotal?nftContract=${contractHash}`
      } else {
        url = `${isGen0 ? currentNetwork?.urlApiGen0 : currentNetwork?.urlApi}/gettotal`
      }
      const response = await axios.get(url)
      if (response.status === 200 && response.data) {
        return response.data
      }
    } catch (error: any) {
      console.error(error)
      return {
        totalVolume: '',
        floorPrice: '',
        bestOffer: '',
        listedPercentage: '',
        floorBidTokenId: '',
        bestBidTokenId: '',
      }
    }
  }, [])
}
