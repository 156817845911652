import { SectionWrapper, TextHeader, TextTitle, WrapAttribute, WrapTitle } from './style'
import { useEffect, useState } from 'react'
import Attribute from 'components/Attribute'
import { useCurrentNetwork } from 'hooks/useNetwork'
import axios from 'axios'
import { capitalizeFirstLetter } from 'utils'

const dummyAttributes = {
  body: [{ rarity: 'Common', name: 'Glowing', count: '???' }],
  backgrounds: [{ rarity: 'Common', name: 'Glowing', count: '???' }],
  eyes: [{ rarity: 'Common', name: 'Glowing', count: '???' }],
  mouth: [{ rarity: 'Common', name: 'Glowing', count: '???' }],
  hats_hair: [{ rarity: 'Common', name: 'Glowing', count: '???' }],
}
export default function AttributePage() {
  const [attributes, setAttributes] = useState(dummyAttributes)
  const currentNetwork = useCurrentNetwork()
  useEffect(() => {
    async function getAttributes() {
      try {
        const api = `${currentNetwork?.urlApi}/lootbox/gen1Attributes`
        const response = await axios.get(api)
        if (response.status === 200 && response.data) {
          const result = response.data
          setAttributes(result)
        }
      } catch (e) {
        console.log(e)
        setAttributes(dummyAttributes)
      }
    }

    getAttributes()
  }, [])

  return (
    <SectionWrapper>
      <TextHeader>Gen 1 </TextHeader>
      <TextHeader fontWeight={400}>Attributes</TextHeader>
      <WrapTitle>
        <TextTitle>Body</TextTitle>
      </WrapTitle>
      <WrapAttribute>
        {attributes.body.length > 1 &&
          attributes.body.map((e, index) => (
            <Attribute
              key={index}
              type={capitalizeFirstLetter(e?.rarity)}
              name={e?.name}
              quantity={e?.count}
              hasBackground={true}
            ></Attribute>
          ))}
      </WrapAttribute>
      <WrapTitle>
        <TextTitle>Backgrounds</TextTitle>
      </WrapTitle>
      <WrapAttribute>
        {attributes.backgrounds.length > 1 &&
          attributes.backgrounds.map((e, index) => (
            <Attribute
              key={index}
              type={capitalizeFirstLetter(e?.rarity)}
              name={e?.name}
              quantity={e?.count}
              hasBackground={true}
            ></Attribute>
          ))}
      </WrapAttribute>
      <WrapTitle>
        <TextTitle>Eyes</TextTitle>
      </WrapTitle>
      <WrapAttribute>
        {attributes.eyes.length > 1 &&
          attributes.eyes.map((e, index) => (
            <Attribute
              key={index}
              type={capitalizeFirstLetter(e?.rarity)}
              name={e?.name}
              hasAttribute={true}
              quantity={e?.count}
            ></Attribute>
          ))}
      </WrapAttribute>
      <WrapTitle>
        <TextTitle>Mouth</TextTitle>
      </WrapTitle>
      <WrapAttribute>
        {attributes.mouth.length > 1 &&
          attributes.mouth.map((e, index) => (
            <Attribute
              key={index}
              type={capitalizeFirstLetter(e.rarity)}
              name={e?.name}
              hasAttribute={true}
              quantity={e?.count}
            ></Attribute>
          ))}
      </WrapAttribute>
      <WrapTitle>
        <TextTitle>Hats/ Hair</TextTitle>
      </WrapTitle>
      <WrapAttribute>
        {attributes.hats_hair.length > 1 &&
          attributes.hats_hair.map((e, index) => (
            <Attribute
              key={index}
              type={capitalizeFirstLetter(e.rarity)}
              name={e?.name}
              hasAttribute={true}
              quantity={e?.count}
            ></Attribute>
          ))}
      </WrapAttribute>
    </SectionWrapper>
  )
}
