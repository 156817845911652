import Floater from 'pages/RedeemMysteryBox/Floater'
import { WrapImageAtt } from './Style'
import MockAttAvatar from 'assets/images/Attributes/Grey$100 1.png'
import MockAttAvatar2 from 'assets/images/Attributes/Grey$5.png'
import { getName } from 'utils'
import { useEffect, useState } from 'react'
import { useCurrentNetwork } from 'hooks/useNetwork'
import axios from 'axios'

interface ListAttribute {
  metadata: { edition: number; attributes: { trait_type: string; value: string }[] }
}
const dummyAttributes = [{ rarity: '???', name: '???', count: '???' }]

export default function ListAttribute(props: ListAttribute): JSX.Element {
  const { metadata } = props
  const currentNetwork = useCurrentNetwork()
  const [listAttribute, setListAttribute] = useState(dummyAttributes)

  useEffect(() => {
    async function getAttributes() {
      try {
        const api = `${currentNetwork?.urlApi}/lootbox/gen1Attributes`
        const response = await axios.get(api)
        if (response.status === 200 && response.data) {
          const result = response.data
          setListAttribute([
            ...result.body,
            ...result.backgrounds,
            ...result.eyes,
            ...result.mouth,
            ...result.hats_hair,
          ])
        }
      } catch (e) {
        setListAttribute(dummyAttributes)
      }
    }
    getAttributes()
  }, [])
  return (
    <div style={{ flexDirection: 'row', gap: '0.5rem', display: 'flex' }}>
      {metadata?.attributes.map(e => (
        <Floater
          key={e.value}
          name={getName(e.value)}
          listAttribute={listAttribute}
          hasAttribute={e.trait_type !== 'background' && e.trait_type !== 'body'}
          hasBackground={e.trait_type === 'background' || e.trait_type === 'body'}
        >
          <WrapImageAtt>
            {e.trait_type == 'body' ||
              (e.trait_type == 'background' && (
                <img
                  src={`${process.env.PUBLIC_URL}/images/${e.value}.png`}
                  height="auto"
                  style={{ position: 'absolute', width: 37, height: 37 }}
                />
              ))}
            <img
              src={e.value === 'Glowing' ? MockAttAvatar2 : MockAttAvatar}
              height="auto"
              style={{ position: 'absolute', width: 37, height: 37 }}
            ></img>
            {e.trait_type != 'body' && e.trait_type != 'background' && e.value !== 'None' && (
              <img
                src={`${process.env.PUBLIC_URL}/images/${getName(e.value)}.png`}
                height="auto"
                style={{ position: 'absolute', width: 37, height: 37 }}
              />
            )}
          </WrapImageAtt>
        </Floater>
      ))}
    </div>
  )
}
